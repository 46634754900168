import {
  ActionIcon,
  Anchor,
  Avatar,
  Group,
  ScrollArea,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconPencil, IconTrash } from "@tabler/icons";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TitlePage } from "../../components/TitlePage";
import loader from "../../lottie/loader.json";
import users from "../../services/users/users";

interface UsersTableProps {
  data: {
    avatar: string;
    name: string;
    job: string;
    email: string;
    phone: string;
  }[];
}

const jobColors: Record<string, string> = {
  engineer: "blue",
  manager: "cyan",
  designer: "pink",
};

export function Usuarios() {
  const theme = useMantineTheme();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function deleteUser(id_user: string) {
    showNotification({
      id: "load-data",
      loading: true,
      title: "Removendo usuário",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    users
      .delete(id_user)
      .then((res: any) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Usuário removido com sucesso!`,
          autoClose: 2000,
        });
        fetchUsers();
      })
      .catch((err: any) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao remover usuário`,
          autoClose: 2000,
          color: "red",
        });
      });
  }

  const openDeleteModal = (id: string) =>
    modals.openConfirmModal({
      title: "Excluir Usuário",
      centered: true,
      children: (
        <Text size="sm">
          Você tem certeza que deseja <b>excluir este usuário?</b> Esta ação é
          irreversível.
        </Text>
      ),
      labels: { confirm: "Excluir Usuário", cancel: "Cancelar" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => deleteUser(id),
    });

  async function fetchUsers() {
    setLoading(true);
    users
      .all()
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {}, []);

  if (loading) {
    return <Lottie height={50} width={50} animationData={loader} />;
  }

  const rows = data?.map((user: any) => (
    <tr key={user.name}>
      <td>
        <Group spacing="sm">
          <Avatar size={30} src={user.avatar} radius={30} />
          <Text size="sm" weight={500}>
            {user.name}
          </Text>
        </Group>
      </td>

      <td>
        <Anchor<"a">
          size="sm"
          href="#"
          onClick={(event) => event.preventDefault()}
        >
          {user.email}
        </Anchor>
      </td>

      <td>
        <Group spacing={0} position="right">
          <ActionIcon>
            <IconPencil
              onClick={() => navigate(`/usuarios/fundos/${user.id}`)}
              size={50}
              stroke={1.5}
            />
          </ActionIcon>
          <ActionIcon color="red">
            <IconTrash
              size={50}
              stroke={1.5}
              onClick={() => openDeleteModal(user.id)}
            />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <>
      <TitlePage title="Usuários Cadastrados"></TitlePage>
      <ScrollArea>
        <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th />
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
    </>
  );
}
