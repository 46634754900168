import { useEffect, useState } from "react";
import { Styles } from "./styles";

interface BoxProps {
  data?: DataItem;
  negative?: any;
}

interface DataItem {
  id: string;
  isin: string;
  total: number;
  date: string;
  cnpj: string;
}

interface IFundByCnpj {
  produto: string;
}

export default function FundosCotas({ data }: any) {
  const [dataCotas, setDataCotas] = useState<any>([]);

  async function generateObject() {
    const newData: any[] = [];
    data?.map(async (item: any) => {
      newData.push({
        cota: item.produto,
        total: item.posicao
          .toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
          .replace(".", "."),
        // nome: data,
      });
    });

    setDataCotas(newData);
  }

  useEffect(() => {
    generateObject();
  }, [data]);

  return (
    <Styles>
      <table>
        <thead>
          <tr>
            <th>COTA</th>
            <th>POSIÇÃO</th>
            <th>PRODUTO</th>
          </tr>
        </thead>
        <tbody>
          {dataCotas?.map((item: any, index: any) => {
            return (
              <tr key={item.total}>
                <td>{index + 1}</td>
                <td>{item.total}</td>
                <td>{item.cota}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Styles>
  );
}
