import api from "../adm/api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  all: async () => {
    const response = await api.get(`/fundos`);

    return response;
  },

  // Fundos exclusivos alphamar
  byId: async (cnpj: string, params?: any) => {
    const response = await api.get(`/fundos/id/${cnpj}`, { params });

    return response;
  },
  getFoundsByUserId: async (id: string) => {
    const response = await api.get(`/fundos/byuserid/${id}`);
    return response;
  },
  getHistoricPLFoundById: async (cnpj: string) => {
    const response = await api.get(`/fundos/historico/${cnpj}`);
    return response;
  },

  getProfitHistoric: async (cnpj: string) => {
    const response = await api.get(`/fundos/profit-historic/${cnpj}`);

    return response;
  },

  getPositions: async (cnpj: string) => {
    const response = await api.get(`/fundos/positions/${cnpj}`);
    return response;
  },

  // buscar fundo (todos os fundos do brasil) por cnpj.
  getFundByCnpj: async (cnpj: string) => {
    const response = await api.get(`/fundos/fundbycnpj/${cnpj}`);
    return response;
  },

  // buscar fundo (todos os fundos do brasil) por cnpj.
  getProductByIsin: async (isin: string) => {
    const response = await api.get(`/fundos/productclass/${isin}`);
    return response;
  },
};
