import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

interface User {
  id: string;
  email: string;
  name: string;
  status: boolean;
  role: string;
  is_private: boolean;
}

interface AuthState {
  token: string;
  refresh_token: string;
  user: User;
}

type AuthProviderProps = {
  children: ReactNode;
};

interface AuthContextData {
  user: User;
  signOut(): void;
  updateUserData(newUserData: AuthState): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [userData, setUserData] = useState<AuthState>(() => {
    const token = localStorage.getItem("@Alphamar:token");
    const refresh_token = localStorage.getItem("@Alphamar:refresh_token");
    const user = localStorage.getItem("@Alphamar:user");

    if (token && user && refresh_token) {
      const userData = JSON.parse(user);

      return { token, user: userData.user, refresh_token };
    }

    return {} as AuthState;
  });

  const updateUserData = useCallback((newUserData: AuthState) => {
    setUserData(newUserData);
    const userDataString = JSON.stringify(newUserData);
    localStorage.setItem("@Alphamar:token", newUserData.token);
    localStorage.setItem("@Alphamar:refresh_token", newUserData.refresh_token);
    localStorage.setItem("@Alphamar:user", userDataString);
    return;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@Alphamar:token");
    localStorage.removeItem("@Alphamar:user");
    localStorage.removeItem("@Alphamar:refresh_token");

    showNotification({
      title: "Desconectado!",
      message: "Logout realizado com sucesso!",
      color: "green",
      icon: <IconCheck />,
    });

    setUserData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: userData.user, signOut, updateUserData }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
