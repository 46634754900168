import { LoadingOverlay } from "@mantine/core";
import { Line, Styles } from "./styles";

type BoxProps = {
  children?: React.ReactNode;
  data?: object;
  fund_name?: string;
  negative?: any;
  loader: boolean;
};

export default function RentabilidadeHistorica(props: BoxProps) {
  let reverted;

  if (props.data) {
    reverted = Object.entries(props.data).reverse();
  }

  return (
    <Styles negative={true}>
      <LoadingOverlay visible={props.loader} overlayBlur={2} />
      <table>
        <thead>
          <tr>
            <th>ANO</th>
            <th></th>
            <th>JAN</th>
            <th>FEV</th>
            <th>MAR</th>
            <th>ABR</th>
            <th>MAI</th>
            <th>JUN</th>
            <th>JUL</th>
            <th>AGO</th>
            <th>SET</th>
            <th>OUT</th>
            <th>NOV</th>
            <th>DEZ</th>
            <th>ANO</th>
            <th>ACUMULADO</th>
          </tr>
        </thead>
        <tbody>
          {reverted?.map((item: any, index) => (
            <tr key={index}>
              <td>
                <div>{item[0]}</div>
              </td>
              <td>
                <div className="fundo">
                  <div className="icon-fundo"></div>
                  {props.fund_name}
                </div>
                <div className="cdi">
                  <div className="icon-cdi"></div> % CDI
                </div>
              </td>

              <td>
                {item[1]?.fundo["1"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["1"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["1"]
                          ? item[1].fundo["1"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["1"]?.toFixed(8) /
                          item[1].cdi["1"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["1"]?.toFixed(8) /
                            item[1].cdi["1"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["2"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["2"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["2"]
                          ? item[1].fundo["2"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["2"]?.toFixed(8) /
                          item[1].cdi["2"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["2"]?.toFixed(8) /
                            item[1].cdi["2"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["3"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["3"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["3"]
                          ? item[1].fundo["3"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["3"]?.toFixed(8) /
                          item[1].cdi["3"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["3"]?.toFixed(8) /
                            item[1].cdi["3"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["4"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["4"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["4"]
                          ? item[1].fundo["4"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["4"]?.toFixed(8) /
                          item[1].cdi["4"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["4"]?.toFixed(8) /
                            item[1].cdi["4"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["5"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["5"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["5"]
                          ? item[1].fundo["5"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["5"]?.toFixed(8) /
                          item[1].cdi["5"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["5"]?.toFixed(8) /
                            item[1].cdi["5"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["6"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["6"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["6"]
                          ? item[1].fundo["6"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["6"]?.toFixed(8) /
                          item[1].cdi["6"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["6"]?.toFixed(8) /
                            item[1].cdi["6"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["7"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["7"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["7"]
                          ? item[1].fundo["7"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["7"]?.toFixed(8) /
                          item[1].cdi["7"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["7"]?.toFixed(8) /
                            item[1].cdi["7"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["8"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["8"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["8"]
                          ? item[1].fundo["8"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["8"]?.toFixed(8) /
                          item[1].cdi["8"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["8"]?.toFixed(8) /
                            item[1].cdi["8"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["9"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["9"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["9"]
                          ? item[1].fundo["9"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["9"]?.toFixed(8) /
                          item[1].cdi["9"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["9"]?.toFixed(8) /
                            item[1].cdi["9"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["10"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["10"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["10"]
                          ? item[1].fundo["10"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["10"]?.toFixed(8) /
                          item[1].cdi["10"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["10"]?.toFixed(8) /
                            item[1].cdi["10"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["11"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["11"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["11"]
                          ? item[1].fundo["11"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["11"]?.toFixed(8) /
                          item[1].cdi["11"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["11"]?.toFixed(8) /
                            item[1].cdi["11"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["12"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["12"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["12"]
                          ? item[1].fundo["12"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["12"]?.toFixed(8) /
                          item[1].cdi["12"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["12"]?.toFixed(8) /
                            item[1].cdi["12"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["ano"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["ano"]?.toFixed(2) < 0 ? false : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["ano"]
                          ? item[1].fundo["ano"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["ano"]?.toFixed(8) /
                          item[1].cdi["ano"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["ano"]?.toFixed(8) /
                            item[1].cdi["ano"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {item[1]?.fundo["acumulado"] ? (
                  <>
                    {" "}
                    <Line
                      negative={
                        item[1]?.fundo["acumulado"]?.toFixed(2) < 0
                          ? false
                          : true
                      }
                    >
                      <div className="data-fundo">
                        {item[1].fundo["acumulado"]
                          ? item[1].fundo["acumulado"]?.toFixed(2)
                          : "-"}
                      </div>
                    </Line>
                    <Line
                      negative={
                        item[1]?.fundo["acumulado"]?.toFixed(8) /
                          item[1].cdi["acumulado"]?.toFixed(8) <
                        0
                          ? false
                          : true
                      }
                    >
                      <div className="data-cdi">
                        {(
                          (item[1].fundo["acumulado"]?.toFixed(8) /
                            item[1].cdi["acumulado"]?.toFixed(8)) *
                          100
                        ).toFixed()}
                      </div>
                    </Line>
                  </>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Styles>
  );
}
