import {
  Button,
  Card,
  createStyles,
  LoadingOverlay,
  SimpleGrid,
  Title,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCircleCheck } from "@tabler/icons";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ImageCheckbox } from "../../../components/CheckBoxWithImage";
import { TitlePage } from "../../../components/TitlePage";
import admfundos from "../../../services/adm/fundos/fundos";
import fundos from "../../../services/fundos/fundos";
import select from "../../../services/select/select";
import users from "../../../services/users/users";

const useStyles = createStyles((theme) => ({
  title: {
    fontWeight: 800,
    color: theme.colors.dark[3],
    [theme.fn.smallerThan("sm")]: {
      fontSize: 14,
    },
  },
  subTitle: {
    fontWeight: 400,
    color: theme.colors.dark[3],
    [theme.fn.smallerThan("sm")]: {
      fontSize: 14,
    },
  },
}));

export interface IfundosToMap {
  cnpj: string;
  date_invest_init: Date;
  name_fund: string;
  status: string;
  tx_adm: number;
  tx_performace: number;
  checked: boolean;
}

export interface IWalletsToMap {
  ativo: boolean;
  checked: boolean;
  corretora: string;
  data_inicio: string;
  documento: string;
  id: number;
  link_scrap: string;
  nome: string;
  ult_att: string;
}

interface IFounds {
  cnpj: string;
  createdAt: string;
  date_invest_init: Date;
  id: string;
  name_fund: string;
  status: string;
  tx_adm: number;
  tx_performace: number;
  updatedAt: string;
}

export function AddFundo() {
  const { classes, theme } = useStyles();

  const [foundsAll, setFoundsAll] = useState<any>([]);
  const [walletsAll, setWalletsAll] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  //user data (exibir cabeçalho com os dados do usuário.)
  const { data, isError, isLoading } = useQuery(["user", id], () =>
    users.findbyid(id!)
  );

  useEffect(() => {
    //setLoading(true);
    const getDataAndSelectDefault = async () => {
      await getDataAndSelectDefaultFunds();
      await getDataAndSelectDefaultWallets();
      setLoading(false);
    };
    getDataAndSelectDefault();
  }, []);

  const getDataAndSelectDefaultFunds = async () => {
    const items = await fundos.all().then((res) => {
      const data = res.data;

      const addPropCheckedToFunds = data?.map((obj: IFounds) => ({
        ...obj,
        checked: false,
      }));

      return addPropCheckedToFunds;
    });

    const itemsUser = await fundos.getFoundsByUserId(id!).then((res) => {
      return res.data;
    });

    const data = items.map((item: any) => {
      const founded = itemsUser.find((user: any) => {
        return user.cnpj === item.cnpj;
      });
      if (founded) {
        item.checked = true;
      } else {
        item.checked = false;
      }
      return item;
    });

    setFoundsAll(data);
  };

  function handleChangeCheckedFound(cnpj: string) {
    const foundsChanged = foundsAll.map((fund: any) => {
      if (fund.cnpj === cnpj) {
        fund.checked = !fund.checked;
      }

      return fund;
    });
    setFoundsAll(foundsChanged);
  }

  function sendEditFoundData() {
    setVisible(true);
    const founds: { id_fund: string; id_user: string }[] = [];

    const foundsDataToPost = foundsAll.map((item: any) => {
      if (item.checked) {
        founds.push({ id_fund: item.id, id_user: id! });
      }
    });

    const dataToPost = { id_user: id, funds: founds };

    admfundos
      .editar(dataToPost)
      .then((res) => {
        setVisible(false);
        showNotification({
          message: res.data.message,
          color: "green",
          icon: <IconCircleCheck />,
        });
      })
      .catch((res) => {
        setVisible(false);
        showNotification({
          message: res.message,
          color: "red",
          icon: <IconCircleCheck />,
        });
      });
  }

  //wallets

  const getDataAndSelectDefaultWallets = async () => {
    const items = await select.ListWallets().then((res) => {
      const data = res.data;
      console.log(data);

      const addPropCheckedToFunds = data?.map((obj: any) => ({
        ...obj,
        checked: false,
      }));

      return addPropCheckedToFunds;
    });
    const WalletOfUser = await select.getWalletsByUserId(id!).then((res) => {
      return res.data;
    });

    const dataToRender = items.map((item: IWalletsToMap) => {
      const founded = WalletOfUser.id === item.id ? true : false;
      if (founded) {
        item.checked = true;
      } else {
        item.checked = false;
      }
      return item;
    });

    setWalletsAll(dataToRender);
  };

  function handleChangeCheckedWallet(wallet_id: number) {
    const foundsChanged = walletsAll.map((fund: any) => {
      if (fund.id === wallet_id) {
        fund.checked = !fund.checked;
      }

      return fund;
    });
    setWalletsAll(foundsChanged);
  }

  function sendEditWalletData() {
    setVisible(true);
    const wallet: { wallet_id: string; id_user: string }[] = [];

    walletsAll.map((item: any) => {
      if (item.checked) {
        wallet.push({ wallet_id: item.id, id_user: id! });
      }
    });

    const dataToPost = { id_user: id, wallets: wallet };

    select
      .EditWalletsToUserById(dataToPost)
      .then((res) => {
        setVisible(false);
        showNotification({
          message: res.data.message,
          color: "green",
          icon: <IconCircleCheck />,
        });
      })
      .catch((res) => {
        setVisible(false);
        showNotification({
          message: res.message,
          color: "red",
          icon: <IconCircleCheck />,
        });
      });
  }

  return (
    <>
      <TitlePage title="Carteiras e Fundos"></TitlePage>
      <LoadingOverlay visible={loading} overlayBlur={1} />
      <Title order={4} className={classes.title}>
        {data?.data.user}
      </Title>
      <Title order={5} className={classes.subTitle}>
        {data?.data.email}
      </Title>
      <Card>
        <Title order={3} className={classes.title}>
          Fundos Exclusivos
        </Title>
        <LoadingOverlay visible={visible} overlayBlur={1} />
        <SimpleGrid
          cols={4}
          breakpoints={[
            { maxWidth: "lg", cols: 2 },
            { maxWidth: "sm", cols: 1 },
          ]}
        >
          {foundsAll.map((item: IfundosToMap) => (
            <ImageCheckbox
              key={item.cnpj}
              title={item.name_fund}
              description={`CNPJ ${item.cnpj}`}
              onChange={() => {
                handleChangeCheckedFound(item.cnpj);
              }}
              checked={item.checked}
            />
          ))}
        </SimpleGrid>
        <Button
          onClick={() => sendEditFoundData()}
          type="submit"
          mt="xl"
          size="md"
        >
          Salvar
        </Button>
      </Card>
      <Card mt={"xs"}>
        <Title order={3} className={classes.title}>
          Carteiras Administradas
        </Title>

        <SimpleGrid
          cols={4}
          breakpoints={[
            { maxWidth: "lg", cols: 2 },
            { maxWidth: "sm", cols: 1 },
          ]}
        >
          {walletsAll.map((item: IWalletsToMap) => (
            <ImageCheckbox
              key={item.id}
              title={item.nome}
              description={`DOC ${item.documento} ${item.corretora}`}
              onChange={() => {
                handleChangeCheckedWallet(item.id);
              }}
              checked={item.checked}
            />
          ))}
        </SimpleGrid>
        <Button
          onClick={() => sendEditWalletData()}
          type="submit"
          mt="xl"
          size="md"
        >
          Salvar
        </Button>
      </Card>
    </>
  );
}
