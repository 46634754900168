import styled from "@emotion/styled";

interface Props {
  negative?: boolean;
}

const positive = ({ theme }: any) => theme.colors.gray[7];
const negative = ({ theme }: any) => theme.colors.red[6];

export const Line = styled.div<Props>`
  .data-fundo {
    color: ${(props) => (props.negative ? positive : negative)};
    font-weight: 500;
  }

  .data-cdi {
    color: ${(props) => (props.negative ? positive : negative)};
    font-weight: 400;
  }
`;

export const LineCdi = styled.div<Props>`
  .data-fundo {
    color: ${(props) => (props.negative ? positive : negative)};
    font-weight: 500;
  }

  .data-cdi {
    color: ${(props) => (props.negative ? positive : negative)};
    font-weight: 400;
  }
`;

export const Styles = styled.div<Props>`
  overflow-x: auto;

  table {
    width: 100%;
    border-spacing: 0;
    .cdi {
      display: flex;
      justify-content: flex-start;
      font-weight: 800;

      .icon-cdi {
        margin: 0 2px;
        width: 5px;
        height: 15px;
        border-radius: 10000px;
        background-color: ${({ theme }) => theme.colors.orange[6]};
      }
    }

    .fundo {
      display: flex;
      justify-content: flex-start;
      font-weight: 800;
      text-align: left;
    }

    .icon-fundo {
      margin: 0 2px;
      width: 5px;
      height: 15px;
      border-radius: 50px;
      background-color: ${({ theme }) => theme.colors.blue[2]};
    }
  }

  thead td {
    background-color: ${({ theme }) => theme.colors.blue[6]};
    justify-content: center;
    color: white;
    font-weight: 700;
  }
  tr:nth-last-of-type(even) {
    text-align: center;
    background-color: ${({ theme }) => theme.colors.gray[1]};
  }
  tr:nth-last-of-type(odd) {
    text-align: center;
    background-color: ${({ theme }) => theme.colors.gray[3]};
  }
  th,
  td {
    margin: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
    padding: 0.5rem;
    font-size: 0.7rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray[7]};
    :first-of-type() {
      color: white;
      border-right: solid 2px ${({ theme }) => theme.colors.gray[4]};
    }
    :nth-last-of-type(2) {
      border-left: solid 2px ${({ theme }) => theme.colors.gray[4]};
    }

    :first-of-type {
      width: 30px;
      background-color: ${({ theme }) => theme.colors.blue[6]};
      color: white;
      font-weight: 700;
      height: 60px;
      border-bottom: 0.5px solid white;
    }
  }
  th:first-of-type {
    border-right: solid 0.5px white;
    width: 60px;
  }
  th {
    background-color: ${({ theme }) => theme.colors.blue[6]};
    color: white;
    font-weight: 800;
  }
  thead th:nth-last-of-type(-n + 2) {
    background-color: ${({ theme }) => theme.colors.blue[5]};
    width: 100px;
  }
`;
