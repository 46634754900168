import { MediaQuery } from "@mantine/core";
import moment from "moment";
import "moment-timezone";
import "moment/locale/pt-br";

import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatValueResumed } from "../../../helpers/formatNumberResumed";

moment.tz.setDefault("UTC");
moment.locale("pt-BR");

export default function Recharts({ data, print }: any) {
  const formatXAxis = (tickFormat: string) => {
    // console.log(tickFormat, moment(tickFormat).format("MM/YY"));
    return moment(tickFormat).format("MM/YY");
  };

  const formatLabel = (tickFormat: number) => {
    return moment(tickFormat).format("DD/MM/YY");
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "white",
            width: 150,
            display: "flex",
            flexDirection: "column",
            margin: 0,
            borderRadius: 10,
            fontWeight: 300,
            padding: 10,
            border: "none",
            fontSize: 12,
            outline: "none",
            boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
          }}
          className="custom-tooltip"
        >
          {formatLabel(label)}
          <b>{` PL: ${payload[0].value
            .toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
            .replace(".", ".")}`}</b>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="notprint">
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <ResponsiveContainer width={"100%"} height={400}>
            <AreaChart
              data={data}
              margin={{ top: 30, right: 5, left: -14, bottom: 25 }}
            >
              <defs>
                <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="30%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0.0} />
                </linearGradient>
              </defs>

              <XAxis
                dataKey="date"
                tick={{ fontSize: 10, fontWeight: 200 }}
                tickFormatter={(tick) => formatXAxis(tick)}
              />
              <YAxis
                tick={{ fontSize: 11, fontWeight: 400 }}
                tickSize={1}
                domain={[10000000]}
                tickFormatter={(tick) => formatValueResumed(tick)}
              />
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <Tooltip
                wrapperStyle={{ outline: "none" }}
                content={<CustomTooltip />}
              />

              <Area
                type="monotone"
                dataKey="vl_pl"
                stroke="#8884d8"
                fill="url(#colorValue)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </MediaQuery>

        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <ResponsiveContainer width={"100%"} height={250}>
            <AreaChart
              data={data}
              margin={{ top: 30, right: 0, left: -27, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="30%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0.0} />
                </linearGradient>
              </defs>

              <XAxis
                dataKey="date"
                tick={{ fontSize: 10, fontWeight: 200 }}
                tickFormatter={(tick) => formatXAxis(tick)}
              />
              <YAxis
                tick={{ fontSize: 8, fontWeight: 400 }}
                tickSize={1}
                domain={[10000000]}
                tickFormatter={(tick) => formatValueResumed(tick)}
              />
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <Tooltip
                wrapperStyle={{ outline: "none" }}
                content={<CustomTooltip />}
              />
              <Area
                type="monotone"
                dataKey="vl_pl"
                stroke="#8884d8"
                fill="url(#colorValue)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </MediaQuery>
      </div>
      <div className="print">
        <AreaChart width={1700} height={400} data={data}>
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="30%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.0} />
            </linearGradient>
          </defs>

          <XAxis
            dataKey="date"
            tick={{ fontSize: 10, fontWeight: 200 }}
            tickFormatter={(tick) => formatXAxis(tick)}
          />
          <YAxis
            tick={{ fontSize: 11, fontWeight: 400 }}
            tickSize={1}
            domain={[10000000]}
            tickFormatter={(tick) => formatValueResumed(tick)}
          />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Tooltip
            wrapperStyle={{ outline: "none" }}
            content={<CustomTooltip />}
          />
          {/* <Tooltip
              labelStyle={{ color: "black" }}
              itemStyle={{ color: "gray" }}
              formatter={function (value, name) {
                return `${value
                  .toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                  .replace(".", ".")}`;
              }}
              labelFormatter={function (value) {
                return `Data:${formatLabel(value)}`;
              }}
            /> */}
          <Area
            type="monotone"
            dataKey="vl_pl"
            stroke="#8884d8"
            fill="url(#colorValue)"
          />
        </AreaChart>
      </div>
    </>
  );
}
