import { MediaQuery } from "@mantine/core";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { IPostions } from "../../../interfaces/IPositions";

interface PositionProps {
  data?: IPostions;
}

const COLORS = ["#5762c1", "#9b9b9b", "#20c997", "#170b85", "#ad5f16", "#b32e7b"];

export default function Pizza({ data }: PositionProps) {
  const arr: any = [];

  data?.forEach((item) => {
    const have = arr?.findIndex((element: any) => element.amt === item.classe);

    if (
      item.classe === "provisao" ||
      item.classe === "caixa" ||
      item.classe === "despesas"
    ) {
      return;
    }
    if (item.posicao === 0) {
      return;
    }

    if (have === -1) {
      return arr.push({ amt: item.classe, valores: [item.posicao] });
    }

    return arr[have].valores.push(item.posicao);
  });

  const dataItems = arr?.map((item: any) => {
    return {
      ...item,
      total: item.valores.reduce((a: any, b: any) => a + b, 0),
    };
  });

  const renderColorfulLegendText = (value: string, entry: any) => {
    return (
      <span
        style={{
          color: "#596579",
          fontSize: 10,
          fontWeight: 500,
          padding: "10px",
        }}
      >
        {dataItems[value].amt}
      </span>
    );
  };

  return (
    <>
      <div className="print">
        <PieChart width={600} height={350} style={{ marginLeft: -10 }}>
          <Legend
            height={30}
            iconType="circle"
            layout="horizontal"
            verticalAlign="bottom"
            iconSize={10}
            formatter={(amt) => renderColorfulLegendText(amt, amt)}
          />
          <Pie
            labelLine={false}
            width={600}
            height={400}
            data={dataItems}
            dataKey="total"
            nameKey="classe"
            label={({
              cx,
              cy,
              midAngle,
              outerRadius,
              innerRadius,
              percent,
            }) => {
              const RADIAN = Math.PI / 180;
              const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
              const sin = Math.sin(-RADIAN * midAngle);
              const cos = Math.cos(-RADIAN * midAngle);

              const mx = cx + (outerRadius + 6) * cos;
              const my = cy + (outerRadius + 10) * sin;
              const ex = mx + (cos >= 0 ? 1 : -1) * 0;
              const textAnchor = cos >= 0 ? "start" : "end";

              return (
                <text
                  x={ex + (cos >= 0 ? 1 : -1) * 2}
                  y={my}
                  fontSize="11"
                  fontWeight={700}
                  textAnchor={textAnchor}
                >{`${(percent * 100).toFixed(2)}%`}</text>
              );
            }}
            outerRadius={100}
          >
            {dataItems?.map((_: any, index: any) => (
              <Cell key={index} fill={COLORS[index]} />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div className="notprint">
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart style={{ marginLeft: -10 }}>
              <Legend
                height={30}
                iconType="circle"
                layout="horizontal"
                verticalAlign="bottom"
                iconSize={10}
                formatter={(amt) => renderColorfulLegendText(amt, amt)}
              />
              <Pie
                labelLine={false}
                data={dataItems}
                dataKey="total"
                nameKey="classe"
                label={({
                  cx,
                  cy,
                  midAngle,
                  outerRadius,
                  innerRadius,
                  percent,
                }) => {
                  const RADIAN = Math.PI / 180;
                  const radius =
                    innerRadius + (outerRadius - innerRadius) * 0.5;
                  const sin = Math.sin(-RADIAN * midAngle);
                  const cos = Math.cos(-RADIAN * midAngle);

                  const mx = cx + (outerRadius + 6) * cos;
                  const my = cy + (outerRadius + 10) * sin;
                  const ex = mx + (cos >= 0 ? 1 : -1) * 0;
                  const textAnchor = cos >= 0 ? "start" : "end";

                  return (
                    <text
                      x={ex + (cos >= 0 ? 1 : -1) * 2}
                      y={my}
                      fontSize="11"
                      fontWeight={700}
                      textAnchor={textAnchor}
                    >{`${(percent * 100).toFixed(2)}%`}</text>
                  );
                }}
                outerRadius={100}
              >
                {dataItems?.map((_: any, index: any) => (
                  <Cell key={index} fill={COLORS[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </MediaQuery>

        {
          //GRÁFICO PARA MOBILE
        }
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart style={{ marginLeft: -10 }}>
              <Legend
                height={30}
                iconType="circle"
                layout="horizontal"
                verticalAlign="bottom"
                iconSize={10}
                formatter={(amt) => renderColorfulLegendText(amt, amt)}
              />
              <Pie
                labelLine={false}
                data={dataItems}
                dataKey="total"
                nameKey="classe"
                label={({
                  cx,
                  cy,
                  midAngle,
                  outerRadius,
                  innerRadius,
                  percent,
                }) => {
                  const RADIAN = Math.PI / 180;
                  const radius =
                    innerRadius + (outerRadius - innerRadius) * 0.5;
                  const sin = Math.sin(-RADIAN * midAngle);
                  const cos = Math.cos(-RADIAN * midAngle);

                  const mx = cx + (outerRadius + 5) * cos;
                  const my = cy + (outerRadius + 9) * sin;
                  const ex = mx + (cos >= 0 ? 1 : -1) * 0;
                  const textAnchor = cos >= 0 ? "start" : "end";

                  return (
                    <text
                      x={ex + (cos >= 0 ? 1 : -1) * 2}
                      y={my}
                      fontSize="11"
                      fontWeight={700}
                      textAnchor={textAnchor}
                    >{`${(percent * 100).toFixed(2)}%`}</text>
                  );
                }}
                outerRadius={100}
              >
                {dataItems?.map((_: any, index: any) => (
                  <Cell key={index} fill={COLORS[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </MediaQuery>
      </div>
    </>
  );
}
