import {
  Anchor,
  Button,
  Center,
  createStyles,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";

import {
  IconAt,
  IconCircleCheck,
  IconExclamationMark,
  IconLock,
  IconUser,
} from "@tabler/icons";
import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoIcon from "../../assets/icon.svg";
import { PasswordValidation } from "../../components/input/passwordValidation";
import SignUpCredentials from "../../interfaces/SignUpCredentials";
import register from "../../services/register";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundImage: "url(https://i.ibb.co/dgRCXSY/MOSESVIX-01135-1.jpg)",
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: "100vh",
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Poppins, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  control: {
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
}));

export function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const signUp = useCallback(async (dataPost: SignUpCredentials) => {
    setVisible(true);
    register.register(dataPost).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setVisible(false);
        showNotification({
          title: "Confirme seu registro no e-mail",
          message: res.data.message,
          color: "green",
          icon: <IconCircleCheck />,
        });
        return navigate("/login");
      }

      if (
        res.status === 401 ||
        res.status === 403 ||
        res.status === 409 ||
        res.status === 400
      ) {
        setVisible(false);
        return showNotification({
          title: "Erro!",
          message: res.data.message,
          color: "red",
          icon: <IconExclamationMark />,
        });
      }
    });
  }, []);

  function handleChangeEmail(value: string) {
    setEmail(value);
  }

  function handleChangePassword(value: string) {
    setPassword(value);
  }

  function onSubmit(values: any) {
    values.password = password;
    delete values.termsOfService;
    signUp(values);
  }

  const form = useForm({
    initialValues: {
      email: "",
      termsOfService: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  return (
    <div>
      <LoadingOverlay visible={visible} overlayBlur={1} />
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={25} shadow="xl">
          <Center mb={-10}>
            <img src={LogoIcon} width={35}></img>
          </Center>
          <Center>
            <Title
              color="blue"
              variant="gradient"
              gradient={{ from: "#7c85d0", to: "#21285f", deg: 95 }}
              inherit
              order={4}
              mt="xs"
            >
              Alphamar Private
            </Title>
          </Center>
          <Title
            order={5}
            weight={300}
            mt={-5}
            mb={5}
            color="#373A40"
            align="center"
          >
            Registre-se <br />
          </Title>
          <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <TextInput
              mb={10}
              label="Endereço de e-mail"
              placeholder="joao@gmail.com"
              icon={<IconAt size={14}></IconAt>}
              size="md"
              onChange={(e) => handleChangeEmail(e.target.value)}
              required
              {...form.getInputProps("email")}
            />

            <TextInput
              required
              mb={10}
              label="Nome Completo"
              placeholder="Pedro Souza"
              icon={<IconUser size={14}></IconUser>}
              size="md"
              onChange={(e) => handleChangeEmail(e.target.value)}
              {...form.getInputProps("name")}
            />

            <PasswordValidation
              OnChangePassword={handleChangePassword}
            ></PasswordValidation>
            <PasswordInput
              mb={10}
              icon={<IconLock size={14}></IconLock>}
              label="Confirme sua senha"
              placeholder="Sua senha"
              mt="md"
              required
              size="md"
              onChange={(e) => handleChangePassword(e.target.value)}
              {...form.getInputProps("confirmpassword")}
            />
            <Button type="submit" fullWidth mt="xl" size="md">
              Registrar
            </Button>
          </form>
          <Text align="left" mt="md">
            Já possui conta?{" "}
            <Anchor component={Link} to="/login" weight={700}>
              Login
            </Anchor>
          </Text>
        </Paper>
      </div>
    </div>
  );
}
