import api from "../adm/api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ListWallets: async () => {
    const response = await api.get(`/select/wallets`);

    return response;
  },

  WalletById: async (wallet_id: string, params?: any) => {
    const response = await api.get(`/select/wallet/${wallet_id}`);

    return response;
  },
  getWalletsByUserId: async (id: string) => {
    const response = await api.get(`/select/wallet/byuserid/${id}`);
    return response;
  },

  EditWalletsToUserById: async (obj: any) => {
    const response = await api.post(`/select/edit`, obj);
    return response;
  },

  getPositions: async (n_carteira: string) => {
    const response = await api.get(`/select/positions/${n_carteira}`);
    return response;
  },

  plEvolutionByWallet: async (wallet: string) => {
    const response = await api.get(`/select/plevolution/${wallet}`);
    return response;
  },

  getProfitWallet: async (wallet: string, params?: any) => {
    const response = await api.get(`/select/profitwallet/${wallet}`, {
      params,
    });

    return response;
  },

  getProfitHistoric: async (wallet: string, params?: any) => {
    const response = await api.get(`/select/profithistoric/${wallet}`);

    return response;
  },

  // buscar fundo (todos os fundos do brasil) por cnpj.
  getFundByCnpj: async (cnpj: string) => {
    const response = await api.get(`/fundos/fundbycnpj/${cnpj}`);
    return response;
  },
};
