import ChangePass from "../interfaces/ChangePass";
import api from "./adm/api";

export default {
  password: async (email: string) => {
    const response = await api
      .post(`user/forgot/${email}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response.response);

    return response;
  },
  checkToken: async (token: string) => {
    const response = await api
      .get(`user/forgot/${token}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response.response);

    return response;
  },
  newPassword: async (data: ChangePass) => {
    const response = await api
      .post(`user/changepass`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response.response);

    return response;
  },
};
