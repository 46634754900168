import api from "../adm/api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  all: async () => {
    const response = await api.get(`user/list`);

    return response;
  },

  findbyid: async (id: string) => {
    const response = await api.get(`user/findbyid/${id}`);

    return response;
  },

  delete: async (id: string) => {
    const response = await api.delete(`/user/${id}`);

    return response;
  },
};
