import { createStyles, Navbar } from "@mantine/core";
import {
  IconChartLine,
  IconHome2,
  IconLogout,
  IconNews,
  IconSettings,
  IconUser,
  IconUsers,
} from "@tabler/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppUi } from "../../context/AppUiContext";
import { useAuth } from "../../context/authContext";
import { UserInfo } from "../UserInfo";

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef("icon");
  return {
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: theme.fn.variant({
          variant: "light",
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
        [`& .${icon}`]: {
          color: theme.fn.variant({
            variant: "light",
            color: theme.primaryColor,
          }).color,
        },
      },
    },
  };
});

const data = [
  { link: "/", label: "Home", icon: IconHome2 },
  { link: "/meusdados", label: "Meus dados", icon: IconUser },
  { link: "", label: "Configurações", icon: IconSettings },
  { link: "/fundos", label: "Fundos", icon: IconChartLine },
  { link: "/usuarios", label: "Usuários", icon: IconUsers },
  { link: "/cartas", label: "Cartas de Gestão", icon: IconNews },
];
export function NavBarAlphaAdmin() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Home");
  const { signOut, user } = useAuth();
  const { handleChangeMenuOpen } = useAppUi();
  const navigate = useNavigate();
  const links = data.map((item) => (
    <a
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        navigate(`${item.link}`);
        event.preventDefault();
        setActive(item.label);
        handleChangeMenuOpen();
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));

  return (
    <Navbar width={{ sm: 300 }} p="md">
      <UserInfo name={user.name} email={user.email} adm={true}></UserInfo>
      <Navbar.Section grow>
        {links}
        <a href="#" className={classes.link} onClick={() => signOut()}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </a>
      </Navbar.Section>

      <Navbar.Section>
        
      </Navbar.Section>
    </Navbar>
  );
}
