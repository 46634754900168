import SignInCredentials from "../interfaces/SignInCredentials";
import api from "./adm/api";

export default {
  login: async (data: SignInCredentials) => {
    const response = await api.post(`/login`, data);

    return response;
  },

  refresh: async () => {
    const response = await api.post(`/refresh`);

    return response;
  },
};
