import {
  Button,
  Container,
  createStyles,
  Group,
  Text,
  Title,
} from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import register from "../../../services/register";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 120,
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
  },

  label: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color: theme.colors[theme.primaryColor][3],

    [theme.fn.smallerThan("sm")]: {
      fontSize: 120,
    },
  },

  title: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,
    color: theme.white,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 540,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
    color: theme.colors[theme.primaryColor][1],
  },
}));

export function ConfirmUser() {
  const { classes } = useStyles();
  const { id } = useParams();
  const [sucess, setSucess] = useState<boolean>();

  const [seconds, setSeconds] = useState(5);
  const interval = useInterval(() => setSeconds((s) => s - 1), 1000);
  const navigate = useNavigate();

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      interval.stop();
      return navigate("/login");
    }
  }, [seconds]);

  useEffect(() => {
    if (id) {
      register.confirm(id).then((res) => {
        if (
          res.data.statusCode === 500 ||
          res.data.statusCode === 403 ||
          res.data.statusCode === 401
        ) {
          return setSucess(false);
        }
        if (res.data.statusCode === 201 || res.data.statusCode === 200) {
          return setSucess(true);
        }
      });
    } else {
      setSucess(false);
    }
  }, [id]);

  return (
    <div style={{ height: "100vh" }} className={classes.root}>
      {sucess && (
        <span>
          {" "}
          <Container>
            <Title className={classes.title}>
              Usuário confirmado com sucesso!
            </Title>
            <Text size="lg" align="center" className={classes.description}>
              O seu cadastro foi confirmado com sucesso! Agora você já pode
              acessar a plataforma <b>Alphamar Private</b>
            </Text>
            <Text size="lg" align="center" className={classes.description}>
              Você será direcionado para a tela de login em: {seconds} segs
            </Text>
            <Group position="center">
              <Button variant="white" component={Link} to="/login" size="md">
                Voltar para Login
              </Button>
            </Group>
          </Container>
        </span>
      )}

      {sucess === false && (
        <span>
          {" "}
          <Container>
            <Title className={classes.title}>
              Usuário já confirmado e/ou link inválido!
            </Title>
            <Text size="lg" align="center" className={classes.description}>
              O cadastro deste usuário já foi confirmado ou o link de
              confirmação está inválido.
            </Text>

            <Group position="center">
              <Button variant="white" component={Link} to="/login" size="md">
                Voltar para Login
              </Button>
            </Group>
            <Text size="lg" align="center" className={classes.description}>
              Você será <b>redirecionado automaticamente</b> em: {seconds} segs
            </Text>
          </Container>
        </span>
      )}
    </div>
  );
}
