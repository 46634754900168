import {
  Badge,
  createStyles,
  Divider,
  Group,
  Paper,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconBusinessplan } from "@tabler/icons";
import Moment from "react-moment";
Moment.globalFormat = "D MMM YYYY";

const useStyles = createStyles((theme) => ({
  title: {
    fontWeight: 900,
    marginTop: "0.5rem",
    color: theme.colors.dark[3],
    [theme.fn.smallerThan("sm")]: {
      fontSize: 20,
    },
  },
  item: {
    gap: 0,
    display: "flex",
    flexDirection: "column",
    margin: 0,
    textAlign: "center",
  },
  text: {
    fontWeight: 400,
    margin: 0,
    color: theme.colors.dark[2],
    [theme.fn.smallerThan("sm")]: {
      fontSize: 10,
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 85,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  card: {
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
    transition: "transform 150ms ease, box-shadow 100ms ease",
    padding: theme.spacing.xl,
    paddingLeft: theme.spacing.xl * 1.5,

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.02)",
    },

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: 10,
      backgroundImage: theme.fn.linearGradient(
        0,
        theme.colors.blue[4],
        theme.colors.blue[6]
      ),
    },
  },
}));

interface CardGradientProps {
  title: string;
  description: string;
}

export function CardFundo({ data }: any) {
  const { classes, theme } = useStyles();

  return (
    <Paper withBorder radius="md" className={classes.card}>
      <ThemeIcon
        size="xl"
        radius="md"
        variant="gradient"
        gradient={{ deg: 0, from: "pink", to: "orange" }}
      >
        <IconBusinessplan size={28} stroke={1.5} />
      </ThemeIcon>

      <Group>
        <Title order={3} className={classes.title}>
          {data.name_fund}
        </Title>
        <Badge color="green" variant="dot">
          Ativo
        </Badge>
      </Group>
      <Group mt="xs">
        <Text className={classes.text}>
          <b>CNPJ</b> {data.cnpj}
        </Text>
        <Text className={classes.text}>
          <Moment>{data.date_invest_init}</Moment>
        </Text>
      </Group>
      <Divider my="xs" color={theme.colors.gray[2]} />

      <Group mt="xs" position="left" noWrap spacing="xl">
        <Group className={classes.item}>
          <Text className={classes.text}>Tx. de Administração</Text>
          <Text className={classes.text}>
            <b>{data.tx_adm}%</b>
          </Text>
        </Group>
        <Group className={classes.item}>
          <Text className={classes.text}>Tx de Performace</Text>
          <Text className={classes.text}>
            <b>{data.tx_performace}%</b>
          </Text>
        </Group>
        <Group className={classes.item}>
          <Text className={classes.text}>Pratrimônio Líquido</Text>
          <Text className={classes.text}>
            <b>R$ -</b>
          </Text>
        </Group>
      </Group>
    </Paper>
  );
}
