import {
  Anchor,
  Button,
  Center,
  createStyles,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { IconCircleCheck, IconExclamationMark } from "@tabler/icons";
import LogoIcon from "../../assets/icon.svg";
import { useAuth } from "../../context/authContext";
import SignInCredentials from "../../interfaces/SignInCredentials";
import api from "../../services/adm/api";
import auth from "../../services/auth";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundImage: "url(https://i.ibb.co/dgRCXSY/MOSESVIX-01135-1.jpg)",
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: "100vh",
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Poppins, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export function Login() {
  const [visible, setVisible] = useState(false);
  const { updateUserData } = useAuth();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Email inválido"),
      password: (value) => (/^\S+$/.test(value) ? null : "Senha obrigatória"),
    },
  });

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    setVisible(true);
    auth
      .login({ email, password })
      .then((res) => {
        setVisible(false);
        updateUserData(res.data);
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token}`;

        showNotification({
          message: "Login realizado com sucesso!",
          color: "green",
          icon: <IconCircleCheck />,
        });

        return navigate("/");
      })
      .catch(({ ...response }) => {
        const { message } = response.response.data;
        setVisible(false);
        return showNotification({
          title: "Erro ao logar!",
          message:
            message || "Erro ao conectar com a API, favor contatar o suporte.",
          color: "red",
          icon: <IconExclamationMark />,
        });
      });
  }, []);

  const onSubmit = async (values: any) => {
    await signIn(values);
  };

  const { classes } = useStyles();
  return (
    <div>
      <LoadingOverlay visible={visible} overlayBlur={1} />
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={25} shadow="xl">
          <Center mb={-10}>
            <img src={LogoIcon} width={35}></img>
          </Center>
          <Center>
            <Title
              color="blue"
              variant="gradient"
              gradient={{ from: "#7c85d0", to: "#21285f", deg: 95 }}
              inherit
              order={4}
              mt="xs"
            >
              Alphamar Private
            </Title>
          </Center>
          <Title
            order={5}
            weight={300}
            mt={-5}
            mb={5}
            color="#373A40"
            align="center"
          >
            Login <br />
          </Title>
          <form onSubmit={form.onSubmit((values: any) => onSubmit(values))}>
            <TextInput
              label="Endereço de e-mail"
              placeholder="joao@gmail.com"
              size="md"
              {...form.getInputProps("email")}
            />

            <PasswordInput
              label="Senha"
              placeholder="Sua senha"
              mt="md"
              size="md"
              {...form.getInputProps("password")}
            />

            <Anchor
              component={Link}
              to="/recuperarsenha"
              sx={(theme) => ({
                paddingTop: 2,
                color:
                  theme.colors[theme.primaryColor][
                    theme.colorScheme === "dark" ? 4 : 6
                  ],
                fontWeight: 500,
                fontSize: theme.fontSizes.xs,
              })}
              weight={700}
            >
              Esqueceu sua senha?
            </Anchor>
            <Button type="submit" fullWidth mt="xl" size="md">
              Login
            </Button>
          </form>

          <Text align="left" mt="md">
            Não tem conta?{" "}
            <Anchor component={Link} to="/registrar" weight={700}>
              Registre-se
            </Anchor>
          </Text>
        </Paper>
      </div>
    </div>
  );
}
