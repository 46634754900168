import {
  Button,
  Card,
  Center,
  Chip,
  createStyles,
  Flex,
  Grid,
  Group,
  Modal,
  Text,
  Title,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { IconCalendarEvent } from "@tabler/icons";
import { useQuery } from "@tanstack/react-query";

import anbima from "../../assets/anbima.png";
import logo from "../../assets/logo-color.png";
import loader from "../../lottie/loader.json";

import { IconPrinter } from "@tabler/icons";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title as TitleChart,
  Tooltip,
} from "chart.js";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Recharts from "../../components/charts/areaChart";
import BarChartComponent from "../../components/charts/bar";
import LineCharts from "../../components/charts/lineChart";
import Pizza from "../../components/charts/pizza";
import { HeaderFundo } from "../../components/HeaderFundo";
import { Positions } from "../../components/Positions";
import RentabilidadeHistorica from "../../components/Tables/index";

import { useReactToPrint } from "react-to-print";
import { useAuth } from "../../context/authContext";
import benchmarcks from "../../services/benchmarks/benchmarcks";
import fundos from "../../services/fundos/fundos";
import "./print.scss";
import { WithOutFound } from "./withoutFound";

import { AxiosResponse } from "axios";
import Lottie from "lottie-react";
import "moment-timezone";
import "moment/locale/pt-br";
import Moment from "react-moment";
import { ProfitCards } from "../../components/Cards/ProfitCards";
import { HeaderWallet } from "../../components/HeaderWallet";
import { ICotasBenchmark } from "../../interfaces/ICotasBenchmark";
import { ICotasFundos } from "../../interfaces/ICotasFundo";
import { IDataProfitHistoric } from "../../interfaces/IDataProfitHistoric";
import { Ifundos } from "../../interfaces/IFundos";
import { IHistoricPL } from "../../interfaces/IHistoricPL";
import { IPostions } from "../../interfaces/IPositions";
import select from "../../services/select/select";

moment.tz.setDefault("UTC");
moment.locale("pt-BR");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TitleChart,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  type: "line",
  pointStyle: "cross",
  pointRadius: 0,
  tension: 0.2,
  hitRadius: 0,
  fill: false,
  borderWidth: 1,
  maintainAspectRatio: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    label: {},
    title: {
      display: false,
      text: "título",
    },
  },
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
    y1: {
      type: "linear" as const,
      display: false,
      position: "right" as const,

      grid: {
        drawOnChartArea: false,
      },
    },
  },
};


export function Home() {
  const [start_date, setStartDate] = useState<string | Date | null | undefined>(
    null
  );
  const [end_date, setEndDate] = useState<string | Date | null | undefined>(
    null
  );

  const [start_date_input, setStartDateInput] = useState<
    string | Date | null | undefined
  >(null);
  const [end_date_input, setEndDateInput] = useState<
    string | Date | null | undefined
  >(null);

  const [cnpj, setCnpj] = useState<string>("");
  const [ dataFundsOfUser, setDataFundsOfUser] = useState<any>()
  const [wallet, setWallet] = useState("");
  const [firstDayOfFund, setFirstDayOfFund] = useState<
    string | Date | null | undefined
  >();
  const [opened, { close, open }] = useDisclosure(false);
  const [print, setPrint] = useState(false);

  const [isLoading, setIsLoading] = useState(false)

  const [next, setNext] = useState(false)

  const handleCollapse = () => {
    setPrint((prevState) => !prevState);
  };

  let params = { start_date, end_date };

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => handleCollapse(),
    documentTitle: "Alphamar Fund",
  });

  const handlePrintButon = () => {
    handleCollapse();
    setTimeout(() => {
      handlePrint();
    }, 10);
  };

  const useStyles = createStyles((theme, _params, getRef) => ({
    modal: {
      display: "flex",
      flexDirection: "column",
    },
    bodyText: {
      color: theme.colors.dark[2],
      marginLeft: 7,
    },
    titlePage: {
      fontSize: 26,
      fontWeight: 500,
      color: theme.colors.blue[6],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 20,
      },

      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: theme.colors.blue[6],
        width: 100,
        height: 2,
        marginBottom: 15,
      },
    },
    title: {
      fontSize: 34,
      fontWeight: 900,
      [theme.fn.smallerThan("sm")]: {
        fontSize: 24,
      },
    },
    select: {
      [theme.fn.smallerThan("sm")]: {
        width: "160px",
      },
    },

    top: {
      justifyContent: "space-between",
      padding: "5px 0px",

      [theme.fn.smallerThan("sm")]: {
        justifyContent: "space-between",
        marginBottom: 10,
        padding: "0px",
      },
    },
    root: {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
      boxShadow: theme.shadows.xs,
      border: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[1]
      }`,
    },

    active: {
      backgroundImage: theme.fn.gradient({
        from: "blue",
        to: "blue",
      }),
    },

    control: {
      border: "0 !important",
    },

    labelActive: {
      color: `${theme.white} !important`,
    },

    description: {
      maxWidth: 600,
      margin: "auto",

      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: theme.fn.primaryColor(),
        width: 45,
        height: 2,
        marginTop: theme.spacing.sm,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },

    card: {
      border: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1]
      }`,
      display: "flex",
      flexDirection: "column",
    },

    cardTitle: {
      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: theme.fn.primaryColor(),
        width: 75,
        height: 2,
        marginTop: theme.spacing.xs,
      },
    },
    label: {
      "&[data-checked]": {
        "&, &:hover": {
          backgroundColor: theme.colors.blue[theme.fn.primaryShade()],
          color: theme.white,
          border: "none",
          boxShadow: "none",
        },

        [`& .${getRef("iconWrapper")}`]: {
          color: theme.white,
        },
      },
    },

    text: {
      fontWeight: 500,
      margin: 0,
      color: theme.colors.dark[2],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 10,
      },
    },

    iconWrapper: {
      ref: getRef("iconWrapper"),
    },
  }));

  const { classes, theme } = useStyles();
  const {
    user,
    user: { is_private },
  } = useAuth();

function getInfoUser() {
  setIsLoading(true)

  user.is_private
      ? fundos
          .getFoundsByUserId(user.id!)
          .then((res: AxiosResponse<Ifundos>) => {
            setStartDate(res.data[0].date_invest_init);
            setCnpj(res.data[0].cnpj);
            const originalDate = new Date(res.data[0].date_invest_init);
            setFirstDayOfFund(originalDate);
            setStartDate(originalDate);
            
            setDataFundsOfUser(res.data[0])
            setIsLoading(false)
          })
      : select.getWalletsByUserId(user.id!).then((res: AxiosResponse<any>) => {
          setStartDate(res.data.data_inicio);
          setWallet(res.data.n_carteira);
          const originalDate = new Date(res.data.data_inicio);
          setFirstDayOfFund(originalDate);
          setStartDate(originalDate);
          setDataFundsOfUser(res.data)
          setIsLoading(false)
        })

}

useEffect(()=>{
  getInfoUser()
},[])

useEffect(()=>{
  if (dataFundsOfUser) {
    setNext(true)
  }
},[dataFundsOfUser])    

  const { data: dataFund, refetch: refetchFunds } = useQuery(
    ["funds", user.id],
    () =>
      is_private
        ? fundos.byId(dataFundsOfUser?.cnpj, params).then((res: AxiosResponse<ICotasFundos>) => {
            const lastDayOfFund = res.data[res.data.length - 1]?.data;
            setEndDate(lastDayOfFund);
            return res.data;
          })
        : select
            .getProfitWallet(dataFundsOfUser?.n_carteira, params)
            .then((res: AxiosResponse<ICotasFundos>) => {
              const lastDayOfFund = res.data[res.data.length - 1].data;
              setEndDate(lastDayOfFund);

              const originalDate = new Date(res.data[0].data);
              setStartDate(originalDate);

              return res.data;
            }),
    {
      enabled: !!dataFundsOfUser && !!next,
    }
  );


  const { data: dataHistoricFund } = useQuery(
    ["dataHistoricFund", user.id],
    () =>
      is_private
        ? fundos
            .getHistoricPLFoundById(cnpj)
            .then((res: AxiosResponse<IHistoricPL>) => res.data)
        : select
            .plEvolutionByWallet(wallet)
            .then((res: AxiosResponse<IHistoricPL>) => res.data),
    {
      enabled: !!dataFundsOfUser && !!next,
    }
  );

  //rota para buscar benchmark.
  let filteredCdi: any[];
  const { data: dataBenchmark, refetch: refetchBenchmark } = useQuery(
    ["benchmark"],
    () => {
      if (!end_date) {
        return Promise.resolve([]);
      }

      return benchmarcks
        .cdi({ start_date, end_date })
        .then((res: AxiosResponse<ICotasBenchmark>) => {
          filteredCdi = res.data?.filter((cdiItem: any) => {
            return dataFund?.some(
              (fundItem: any) => fundItem.data === cdiItem.data
            );
          });
          return filteredCdi;
        });
    },
    {
      enabled: !!dataFund && !!start_date,
    }
  );

  //rentabilidade Historica
  const { data: dataProfitHistoric, isLoading: LoadingRentabilidade } =
    useQuery(
      ["profitHistoric", user.id],
      () =>
        is_private
          ? fundos
              .getProfitHistoric(cnpj)
              .then((res: AxiosResponse<IDataProfitHistoric>) => res.data)
          : select
              .getProfitHistoric(wallet)
              .then((res: AxiosResponse<IDataProfitHistoric>) => res.data),
      {
        enabled: !!dataFundsOfUser,
      }
    );

  const { data: dataPositions } = useQuery(
    ["Positions", user.id],
    () =>
      is_private
        ? fundos
            .getPositions(cnpj)
            .then((res: AxiosResponse<IPostions>) => res.data)
        : select
            .getPositions(wallet)
            .then((res: AxiosResponse<IPostions>) => res.data),
    {
      enabled: !!dataFundsOfUser && !!next,
    }
  );

  async function changeDate() {
    if (!start_date_input || !end_date_input) {
      return alert("o campo data não pode ser vazio");
    }

    if (start_date_input > end_date_input) {
      return alert("a data inicial não pode ser maior que a final");
    }

    // if (start_date_input < firstDayOfFund) {
    //   return alert(
    //     "a data inicial não pode menor que o primeiro dia do fundo / carteira."
    //   );
    // }

    setStartDate(start_date_input);
    setEndDate(end_date_input);

    setTimeout(() => {
      refetchFunds();
      refetchBenchmark();
    }, 100);
  }

  useEffect(() => {
    console.log("DATA INICIAL", firstDayOfFund);
  }, [firstDayOfFund]);

  useEffect(() => {
    console.log("ATUALIZOU O ESTADO", firstDayOfFund);
  }, [firstDayOfFund, refetchBenchmark]);

  useEffect(() => {
    refetchBenchmark();
  }, [end_date, refetchBenchmark]);

  useEffect(() => {
    console.log(start_date, "start date");
    refetchBenchmark();
  }, [start_date, refetchBenchmark]);

  const handleChangeDateChips = async (year?: number) => {
    //se n tiver o ano, vai retornar todo o range
    if (!year) {
      setStartDate(firstDayOfFund);
      setEndDate(new Date());
      setTimeout(() => {
        refetchFunds();
        refetchBenchmark();
      }, 50);
      return;
    }
    let XYearAgo = moment(new Date())
      .subtract(year, "year")
      .format("YYYY-MM-DD");

    setStartDate(new Date(XYearAgo));
    setEndDate(new Date());
    setTimeout(() => {
      refetchFunds();
      refetchBenchmark();
    }, 50);
  };

  if (isLoading) {
    return (
      <>
        <Center>
          <Lottie animationData={loader} />;
        </Center>
      </>
    );
  }

  if (!dataFundsOfUser) {
    return <WithOutFound />;
  }

  return (
    <>
      <Button size="xs" leftIcon={<IconPrinter />} onClick={handlePrintButon}>
        IMPRIMIR
      </Button>
      <Title order={3} className={classes.titlePage} mt="sm">
        Meu Portfólio
      </Title>
      <div ref={componentRef}>
        <div className="noprint">
          <div className="topoPrint">
            <img
              src={logo}
              alt="logo"
              style={{
                width: "170px",
              }}
            />
            <img
              src={anbima}
              alt="anbima"
              style={{
                width: "120px",
              }}
            />
          </div>
          <>
            <Grid align="stretch">
              <Grid.Col md={12} lg={6}>
                <Flex gap="md" align="stretch" h={"100%"} direction="row">
                  <ProfitCards
                    cdi={[
                      {
                        title: "CDI (Cota)",
                        value: dataBenchmark
                          ? dataBenchmark[
                              dataBenchmark?.length - 1
                            ]?.cota?.toFixed(2)
                          : "",
                        diff: dataBenchmark
                          ? (
                              (dataBenchmark[dataBenchmark?.length - 1]?.cota -
                                1) *
                              100
                            ).toFixed(2)
                          : "",
                      },
                    ]}
                  ></ProfitCards>
                  <ProfitCards
                    cdi={[
                      {
                        title: is_private ? "FUNDO (COTA)" : "CARTEIRA (COTA)",
                        value: dataFund
                          ? dataFund[dataFund.length - 1]?.cota.toFixed(2)
                          : "",
                        diff: dataFund
                          ? (
                              (dataFund[dataFund.length - 1]?.cota - 1) *
                              100
                            ).toFixed(2)
                          : "",
                      },
                    ]}
                  ></ProfitCards>
                  <ProfitCards
                    cdi={[
                      {
                        title: is_private ? "FUNDO / CDI" : "CARTEIRA / CDI",
                        value:
                          dataFund && dataBenchmark
                            ? (
                                dataFund[dataFund?.length - 1]?.cota /
                                dataBenchmark[dataBenchmark?.length - 1]?.cota
                              ).toFixed(2)
                            : "",
                        diff:
                          dataFund && dataBenchmark
                            ? (
                                (((dataFund[dataFund?.length - 1]?.cota - 1) *
                                  100) /
                                  ((dataBenchmark[dataBenchmark?.length - 1]
                                    ?.cota -
                                    1) *
                                    100)) *
                                100
                              ).toFixed(1)
                            : "",
                      },
                    ]}
                  ></ProfitCards>
                </Flex>
              </Grid.Col>
              <Grid.Col md={12} lg={6}>
                {is_private ? (
                  <HeaderFundo
                    data={dataFundsOfUser ? dataFundsOfUser : dataFundsOfUser}
                    pl={
                      dataHistoricFund
                        ? dataHistoricFund[dataHistoricFund?.length - 1]?.vl_pl
                        : undefined
                    }
                  ></HeaderFundo>
                ) : (
                  <HeaderWallet
                    data={dataFundsOfUser}
                    pl={
                      dataHistoricFund
                        ? dataHistoricFund[dataHistoricFund?.length - 1]?.vl_pl
                        : undefined
                    }
                  ></HeaderWallet>
                )}
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col md={12} lg={8}>
                <Card
                  key={"teste"}
                  shadow="xs"
                  radius="md"
                  className={classes.card}
                >
                  <Modal
                    opened={opened}
                    onClose={close}
                    size="auto"
                    title="Personalizar Data"
                  >
                    <Group mt="xl" className={classes.modal}>
                      <DatePicker
                        fullWidth
                        onChange={(e) => setStartDateInput(e)}
                        placeholder="Data Inicial"
                        withAsterisk
                      />
                      <DatePicker
                        fullWidth
                        onChange={(e) => setEndDateInput(e)}
                        placeholder="Data Final"
                        withAsterisk
                      />
                      <Button
                        fullWidth
                        variant="outline"
                        onClick={() => changeDate()}
                      >
                        Aplicar
                      </Button>
                    </Group>
                  </Modal>

                  <Group className={classes.top}>
                    <Text size="lg" className={classes.cardTitle}>
                      {"CDI x Fundo"}
                    </Text>
                    <Flex direction={"column"}>
                      <Flex direction={"row"}>
                        <IconCalendarEvent
                          size={16}
                          stroke={2.5}
                          color={theme.colors.dark[2]}
                        />
                        <Text size="sm" className={classes.bodyText}>
                          <Moment>{start_date?.toString()}</Moment>
                        </Text>
                      </Flex>

                      <Flex direction={"row"}>
                        <IconCalendarEvent
                          size={16}
                          stroke={2.5}
                          color={theme.colors.dark[2]}
                        />
                        <Text size="sm" className={classes.bodyText}>
                          <Moment>{end_date?.toString()}</Moment>
                        </Text>
                      </Flex>
                    </Flex>
                    <Chip.Group
                      defaultValue="tudo"
                      position="center"
                      multiple={false}
                    >
                      <Chip
                        size="xs"
                        classNames={classes}
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                          border: "none",
                        }}
                        onClick={() => handleChangeDateChips()}
                        value="tudo"
                      >
                        TUDO
                      </Chip>
                      <Chip
                        size="xs"
                        classNames={classes}
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                          border: "none",
                        }}
                        onClick={() => handleChangeDateChips(1)}
                        value="react"
                      >
                        1 ANO
                      </Chip>
                      <Chip
                        size="xs"
                        classNames={classes}
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                          border: "none",
                        }}
                        onClick={() => handleChangeDateChips(2)}
                        value="ng"
                      >
                        2 ANOS
                      </Chip>
                      <Chip
                        size="xs"
                        classNames={classes}
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                          border: "none",
                        }}
                        onClick={open}
                        value="personalizado"
                      >
                        PERSONALIZADO
                      </Chip>
                    </Chip.Group>
                  </Group>

                  <LineCharts
                    cdiData={dataBenchmark}
                    fundData={dataFund}
                    print={print}
                  ></LineCharts>
                </Card>
              </Grid.Col>

              <Grid.Col md={12} lg={4}>
                <Card shadow="xs" radius="md" className={classes.card}>
                  <Group className={classes.top} noWrap>
                    <Text size="lg" className={classes.cardTitle}>
                      {"Evolução do PL"}
                    </Text>
                  </Group>
                  <Recharts data={dataHistoricFund} print={print}></Recharts>
                </Card>
              </Grid.Col>
            </Grid>
            <div className="page-break" />

            <Grid>
              <Grid.Col md={12} lg={12}>
                <Card shadow="xs" radius="md" className={classes.card}>
                  <Group className={classes.top} noWrap>
                    <Text size="lg" className={classes.cardTitle}>
                      {"Rentabilidade Histórica"}
                    </Text>
                  </Group>
                  <RentabilidadeHistorica
                    loader={LoadingRentabilidade}
                    fund_name={
                      dataFundsOfUser.name_fund
                        ? dataFundsOfUser.name_fund
                        : "Carteira"
                    }
                    data={dataProfitHistoric}
                  />
                </Card>
              </Grid.Col>

              <Grid.Col md={12} lg={print ? 12 : 6}>
                <Card shadow="xs" radius="md" className={classes.card}>
                  <Group className={classes.top} noWrap>
                    <Text size="lg" className={classes.cardTitle}>
                      {"Alocação por Estratégia"}
                    </Text>
                  </Group>

                  <Pizza data={dataPositions} />
                </Card>
              </Grid.Col>
              <Grid.Col md={12} lg={print ? 12 : 6}>
                <Card shadow="xs" radius="md" className={classes.card}>
                  <Group className={classes.top} noWrap>
                    <Text size="lg" className={classes.cardTitle}>
                      {"Liquidez"}
                    </Text>
                  </Group>

                  <BarChartComponent data={dataPositions} />
                </Card>
              </Grid.Col>
              <div className="page-break-after"></div>
              <Grid.Col md={12} lg={12}>
                <Card shadow="xs" radius="md" className={classes.card}>
                  <Group className={classes.top} noWrap>
                    <Text size="lg" className={classes.cardTitle}>
                      {"Posições"}
                    </Text>
                  </Group>

                  <Positions data={dataPositions} />
                </Card>
              </Grid.Col>
            </Grid>

            <div className="footerPrint">
              <Text className={classes.text}>
                Este documento é um mero informativo, não representando sugestão
                de investimento. A rentabilidade obtida no passado não é
                garantia de rentabilidade futura. A Rentabilidade não é líquida
                de Impostos.Fundos de Investimento não contam com garantia do
                administrador do fundo, do gestor da carteira, de qualquer
                mecanismo de seguro ou, ainda,do Fundo Garantidor de Créditos -
                FGC.
              </Text>
            </div>
          </>
        </div>
        <div className="print">
          <div className="topoPrint">
            <img
              src={logo}
              alt="logo"
              style={{
                width: "170px",
              }}
            />
            <img
              src={anbima}
              alt="anbima"
              style={{
                width: "120px",
              }}
            />
          </div>
          <div className="container-top">
            <div className="card">
              <ProfitCards
                cdi={[
                  {
                    title: "CDI (Cota)",
                    value: dataBenchmark
                      ? dataBenchmark[dataBenchmark.length - 1]?.cota.toFixed(2)
                      : "",
                    diff: dataBenchmark
                      ? (
                          (dataBenchmark[dataBenchmark.length - 1]?.cota - 1) *
                          100
                        ).toFixed(2)
                      : "",
                  },
                ]}
              ></ProfitCards>
            </div>

            <div className="card">
              <ProfitCards
                cdi={[
                  {
                    title: is_private ? "FUNDO (COTA)" : "CARTEIRA (COTA)",
                    value: dataFund
                      ? dataFund[dataFund.length - 1]?.cota.toFixed(2)
                      : "",
                    diff: dataFund
                      ? (
                          (dataFund[dataFund.length - 1]?.cota - 1) *
                          100
                        ).toFixed(2)
                      : "",
                  },
                ]}
              ></ProfitCards>
            </div>

            <div className="card">
              <ProfitCards
                cdi={[
                  {
                    title: is_private ? "FUNDO / CDI" : "CARTEIRA / CDI",
                    value:
                      dataFund && dataBenchmark
                        ? (
                            dataFund[dataFund.length - 1]?.cota /
                            dataBenchmark[dataBenchmark.length - 1]?.cota
                          ).toFixed(2)
                        : "",
                    diff:
                      dataFund && dataBenchmark
                        ? (
                            (((dataFund[dataFund.length - 1]?.cota - 1) * 100) /
                              ((dataBenchmark[dataBenchmark.length - 1]?.cota -
                                1) *
                                100)) *
                            100
                          ).toFixed(1)
                        : "",
                  },
                ]}
              ></ProfitCards>
            </div>
            {is_private ? (
                  <HeaderFundo
                    data={dataFundsOfUser ? dataFundsOfUser : dataFundsOfUser}
                    pl={
                      dataHistoricFund
                        ? dataHistoricFund[dataHistoricFund?.length - 1]?.vl_pl
                        : undefined
                    }
                  ></HeaderFundo>
                ) : (
                  <HeaderWallet
                    data={dataFundsOfUser}
                    pl={
                      dataHistoricFund
                        ? dataHistoricFund[dataHistoricFund?.length - 1]?.vl_pl
                        : undefined
                    }
                  ></HeaderWallet>
                )}
          </div>

          <div>
                  <Group className={classes.top} noWrap>
                    <Text size="lg" className={classes.cardTitle}>
                      {"Evolução do PL"}
                    </Text>
                  </Group>
                  <Recharts data={dataHistoricFund} print={print}></Recharts>


            </div>
          <div className="container1">
            <Card shadow="xs" radius="md" className={classes.card}>
              <Flex justify={"space-around"}>
                <Text size="lg" className={classes.cardTitle}>
                  {"CDI x Fundo"}
                </Text>

                <Flex direction={"column"}>
                  <Flex direction={"row"}>
                    <IconCalendarEvent
                      size={16}
                      stroke={2.5}
                      color={theme.colors.dark[2]}
                    />
                    <Text size="sm" className={classes.bodyText}>
                      <Moment>{start_date?.toString()}</Moment>
                    </Text>
                  </Flex>

                  <Flex direction={"row"}>
                    <IconCalendarEvent
                      size={16}
                      stroke={2.5}
                      color={theme.colors.dark[2]}
                    />
                    <Text size="sm" className={classes.bodyText}>
                      <Moment>{end_date?.toString()}</Moment>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <LineCharts
                cdiData={dataBenchmark}
                fundData={dataFund}
                print={print}
              ></LineCharts>
            </Card>
          </div>
          <Card shadow="xs" radius="md" className={classes.card}>
            <Group className={classes.top} noWrap>
              <Text size="lg" className={classes.cardTitle}>
                {"Rentabilidade Histórica"}
              </Text>
            </Group>
            <RentabilidadeHistorica
              loader={LoadingRentabilidade}
              fund_name={dataFundsOfUser.name_fund
                        ? dataFundsOfUser.name_fund
                        : "Carteira"}
              data={dataProfitHistoric}
            />
          </Card>

          <div className="container2">
            <div className="alocacao">
              <Card shadow="xs" radius="md" className={classes.card}>
                <Group className={classes.top} noWrap>
                  <Text size="lg" className={classes.cardTitle}>
                    {"Alocação por Estratégia"}
                  </Text>
                </Group>

                <Pizza data={dataPositions} />
              </Card>
            </div>
            <div className="liquidez">
              <Card shadow="xs" radius="md" className={classes.card}>
                <Group className={classes.top} noWrap>
                  <Text size="lg" className={classes.cardTitle}>
                    {"Liquidez"}
                  </Text>
                </Group>

                <BarChartComponent data={dataPositions} />
              </Card>
            </div>
          </div>
          <div className="page-break" />
          <div className="container1">
            <Card shadow="xs" radius="md" className={classes.card}>
              <Group className={classes.top} noWrap>
                <Text size="lg" className={classes.cardTitle}>
                  {"Posições"}
                </Text>
              </Group>

              <Positions data={dataPositions} />
            </Card>

            <div className="footerPrint">
              <Text className={classes.text}>
                Este documento é um mero informativo, não representando sugestão
                de investimento. A rentabilidade obtida no passado não é
                garantia de rentabilidade futura. A Rentabilidade não é líquida
                de Impostos.Fundos de Investimento não contam com garantia do
                administrador do fundo, do gestor da carteira, de qualquer
                mecanismo de seguro ou, ainda,do Fundo Garantidor de Créditos -
                FGC.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
