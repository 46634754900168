export const formatValueResumed = (value: number) => {
  const string = value.toString();

  if (value === 0) {
    return "";
  }

  if (value >= 1000000000) {
    const firstNumber = string.substring(0, 3);
    return `${firstNumber} MM`;
  }

  if (value >= 100000000) {
    const firstNumber = string.substring(0, 3);
    return `${firstNumber} MM`;
  }

  if (value >= 10000000) {
    const firstNumber = string.substring(0, 2);
    return `${firstNumber} MM`;
  }

  if (value >= 1000000) {
    const firstNumber = string.substring(0, 1);
    return `${firstNumber} MM`;
  }

  if (value >= 100000) {
    const firstNumber = string.substring(0, 3);
    return `${firstNumber} m`;
  }

  if (value < 100000) {
    const firstNumber = string.substring(0, 2);
    return `${firstNumber} m`;
  }

  return string;
};
