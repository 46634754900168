import { Button, createStyles, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAt, IconUser } from "@tabler/icons";
import { useState } from "react";
import { TitlePage } from "../../components/TitlePage";
import { useAuth } from "../../context/authContext";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundImage: "url(https://i.ibb.co/dgRCXSY/MOSESVIX-01135-1.jpg)",
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: "100vh",
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },
}));

export function MeusDados() {
  const [visible, setVisible] = useState(false);
  const { user } = useAuth();

  const form = useForm({
    initialValues: {
      email: "",
      termsOfService: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const { classes } = useStyles();
  return (
    <div>
      <TitlePage title="Meus Dados"></TitlePage>
      <TextInput
        mb={10}
        label="Nome"
        placeholder="Digite seu nome"
        defaultValue={user.name}
        icon={<IconUser size={14}></IconUser>}
        size="md"
        {...form.getInputProps("name")}
      />
      <TextInput
        mb={10}
        label="Email"
        placeholder="Digite seu nome"
        defaultValue={user.email}
        icon={<IconAt size={14}></IconAt>}
        size="md"
        {...form.getInputProps("name")}
      />
      <Button type="submit" fullWidth mt="xl" size="md">
        Atualizar
      </Button>
    </div>
  );
}
