import { Navigate, Route, Routes } from "react-router-dom";
import AppBase from "../components/AppShell";
import { useAuth } from "../context/authContext";
import { CartasGestao } from "../pages/cartas";

import { ForgotInvalidToken } from "../pages/forgot/invalidToken";
import { NewPassword } from "../pages/forgot/newpassword";
import { Forgot } from "../pages/forgot/recuperarsenha";
import { Fundos } from "../pages/fundos";
import { Home } from "../pages/home/Home";

import { Login } from "../pages/login";
import { MeusDados } from "../pages/MeusDados";
import { Register } from "../pages/register";
import { ConfirmUser } from "../pages/register/confirmUser";
import { Usuarios } from "../pages/Usuarios";
import { AddFundo } from "../pages/Usuarios/AddFundo";

interface ReactRouterDomProps {
  redirectTo: string;
  children: JSX.Element;
}

export const Rotas = () => {
  const { user } = useAuth();

  const PrivateRoute = ({ children, redirectTo }: ReactRouterDomProps) =>
    user ? children : <Navigate to={redirectTo} />;

  const AdmRoute = ({ children, redirectTo }: ReactRouterDomProps) =>
    user.role === "admin" ? children : <Navigate to={redirectTo} />;

  const PublicRoute = ({ children, redirectTo }: ReactRouterDomProps) =>
    user ? <Navigate to={redirectTo} /> : children;

  return (
    <Routes>
      <Route
        path="/fundos"
        element={
          <PrivateRoute redirectTo="/login">
            <AppBase>
              <Fundos />
            </AppBase>
          </PrivateRoute>
        }
      />
      ,
      <Route
        path="/cartas"
        element={
          <PrivateRoute redirectTo="/login">
            <AppBase>
              <CartasGestao />
            </AppBase>
          </PrivateRoute>
        }
      />
      ,
      <Route
        path="/meusdados"
        element={
          <PrivateRoute redirectTo="/login">
            <AppBase>
              <MeusDados />
            </AppBase>
          </PrivateRoute>
        }
      />
      ,
      <Route
        path="/usuarios"
        element={
          <PrivateRoute redirectTo="/login">
            <AdmRoute redirectTo="/">
              <AppBase>
                <Usuarios />
              </AppBase>
            </AdmRoute>
          </PrivateRoute>
        }
      />
      ,
      <Route
        path="/usuarios/fundos/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <AdmRoute redirectTo="/">
              <AppBase>
                <AddFundo />
              </AppBase>
            </AdmRoute>
          </PrivateRoute>
        }
      />
      ,
      <Route
        path="/"
        element={
          <PrivateRoute redirectTo="/login">
            <AppBase>
              <Home />
            </AppBase>
          </PrivateRoute>
        }
      />
      ,
      <Route
        path="/login"
        element={
          // <PublicRoute redirectTo="/login">
          <Login />
          //</PublicRoute>
        }
      />
      ,
      <Route
        path="/registrar"
        element={
          // <PublicRoute redirectTo="/login">
          <Register />
          //</PublicRoute>
        }
      />
      ,
      <Route
        path="/novasenha"
        element={
          // <PublicRoute redirectTo="/login">
          <NewPassword />
          //</PublicRoute>
        }
      />
      <Route
        path="/novasenha/:id"
        element={
          // <PublicRoute redirectTo="/login">
          <NewPassword />
          //</PublicRoute>
        }
      />
      <Route
        path="/recuperarsenha"
        element={
          // <PublicRoute redirectTo="/login">
          <Forgot />
          //</PublicRoute>
        }
      />
      <Route
        path="/tokeninvalido"
        element={
          // <PublicRoute redirectTo="/login">
          <ForgotInvalidToken />
          //</PublicRoute>
        }
      />
      ,
      <Route
        path="/register/confirm"
        element={
          // <PublicRoute redirectTo="/login">
          <ConfirmUser />
          //</PublicRoute>
        }
      />
      <Route
        path="/register/confirm/:id"
        element={
          // <PublicRoute redirectTo="/login">
          <ConfirmUser />
          //</PublicRoute>
        }
      />
    </Routes>
  );
};
