import {
  Anchor,
  Button,
  Center,
  createStyles,
  LoadingOverlay,
  Paper,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { IconCircleCheck, IconExclamationMark } from "@tabler/icons";
import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoIcon from "../../../assets/icon.svg";
import forgot from "../../../services/forgot";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundImage: "url(https://i.ibb.co/dgRCXSY/MOSESVIX-01135-1.jpg)",
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: "100vh",
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Poppins, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export function Forgot() {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Email inválido"),
    },
  });

  const forgotPassword = useCallback(async (email: string) => {
    setVisible(true);
    forgot.password(email).then((res) => {
      if (res.status === 200 || res.status === 201) {
        showNotification({
          message: res.data.message,
          color: "green",
          icon: <IconCircleCheck />,
        });
        navigate("/login");
        return setVisible(false);
      }

      if (res.status === 401 || res.status === 404) {
        showNotification({
          message: res.data.message,
          color: "red",
          icon: <IconExclamationMark />,
        });
        return setVisible(false);
      }
      return setVisible(false);
    });
  }, []);

  const onSubmit = async (value: any) => {
    const { email } = value;
    await forgotPassword(email);
  };

  const { classes } = useStyles();
  return (
    <div>
      <LoadingOverlay visible={visible} overlayBlur={1} />
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={25} shadow="xl">
          <Center mb={-10}>
            <img src={LogoIcon} width={35}></img>
          </Center>
          <Center>
            <Title
              color="blue"
              variant="gradient"
              gradient={{ from: "#7c85d0", to: "#21285f", deg: 95 }}
              inherit
              order={4}
              mt="xs"
            >
              Alphamar Private
            </Title>
          </Center>
          <Title
            order={5}
            weight={300}
            mt={-5}
            mb={5}
            color="#373A40"
            align="center"
          >
            Recuperar Senha <br />
          </Title>
          <form onSubmit={form.onSubmit((values: any) => onSubmit(values))}>
            <TextInput
              label="Endereço de e-mail"
              placeholder="joao@gmail.com"
              size="md"
              {...form.getInputProps("email")}
            />
            <Button type="submit" fullWidth mt="xs" size="md">
              Resetar senha
            </Button>
          </form>

          <Text align="left" mt="md">
            Possui conta?{" "}
            <Anchor component={Link} to="/login" weight={700}>
              Login
            </Anchor>
          </Text>
        </Paper>
      </div>
    </div>
  );
}
