import styled from "@emotion/styled";

export const Styles = styled.div`
  overflow-x: auto;

  table {
    width: 100%;
    border-spacing: 0;
  }

  thead td {
    background-color: ${({ theme }) => theme.colors.blue[6]};
    justify-content: center;
    color: white;
    font-weight: 700;
  }
  tr:nth-last-of-type(even) {
    text-align: center;
    background-color: ${({ theme }) => theme.colors.gray[1]};
  }
  tr:nth-last-of-type(odd) {
    text-align: center;
    background-color: ${({ theme }) => theme.colors.gray[3]};
  }
  th,
  td {
    margin: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
    padding: 0.5rem;
    font-size: 0.7rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray[7]};
    :first-of-type() {
      color: white;
      border-right: solid 2px ${({ theme }) => theme.colors.gray[4]};
    }
    :nth-last-of-type(2) {
      border-left: solid 2px ${({ theme }) => theme.colors.gray[4]};
    }

    :first-of-type {
      width: 30px;
      background-color: ${({ theme }) => theme.colors.gray[6]};
      color: white;
      font-weight: 700;
      height: 40px;
      border-bottom: 0.5px solid white;
    }
  }
  th:first-of-type {
    border-right: solid 0.5px white;
    width: 30px;
  }
  th {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    color: white;
    font-weight: 800;
  }
  thead th:nth-last-of-type(-n + 2) {
    background-color: ${({ theme }) => theme.colors.gray[5]};
    width: 100px;
  }
`;
