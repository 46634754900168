import {
  Badge,
  Card,
  createStyles,
  Divider,
  Flex,
  Group,
  Text,
  Title,
} from "@mantine/core";
import moment from "moment";
import "moment-timezone";
import "moment/locale/pt-br";

import Moment from "react-moment";

moment.tz.setDefault("UTC");
moment.locale("pt-BR");

interface IpropsHeader {
  data_inicio: string;
  ativo: boolean;
  corretora: string;
  documento: string;
  n_carteira: string;
  id: number;
  link_scrap: string;
  nome: string;
  ult_att: string;
}

interface Props {
  data: IpropsHeader;
  pl?: number;
}

export function HeaderWallet({ data, pl }: Props) {
  const useStyles = createStyles((theme) => ({
    title: {
      fontWeight: 900,
      color: theme.colors.dark[3],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 20,
      },
    },
    item: {
      gap: 1,
      display: "flex",
      flexDirection: "column",
      margin: 0,
      textAlign: "center",
    },
    text: {
      fontWeight: 400,
      margin: 0,
      color: theme.colors.dark[2],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 10,
      },
    },

    description: {
      maxWidth: 600,
      margin: "auto",

      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: theme.fn.primaryColor(),
        width: 85,
        height: 2,
        marginTop: theme.spacing.sm,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },

    card: {
      border: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1]
      }`,
    },
  }));

  console.log(data)

  const { classes, theme } = useStyles();
  return (
    <div>
      <Card shadow="xs">
        <Group>
          <Title order={3} className={classes.title}>
            {data.nome}
          </Title>
          <Badge color="green" variant="dot">
            Ativo
          </Badge>
        </Group>
        <Group mt="xs">
          <Text className={classes.text}>
            <b>Carteira</b> {data.n_carteira}
          </Text>
          <Text className={classes.text}>
            <b>INÍCIO</b>{" "}
            <Moment>
              {data.data_inicio ? data.data_inicio : data.data_inicio}
            </Moment>
          </Text>
        </Group>
        <Divider my="xs" color={theme.colors.gray[2]} />

        <Group mt="xs" position="left" noWrap spacing="xl">
          <Flex>
            <Group className={classes.item}>
              <Text className={classes.text}>PL Atual:</Text>
              <Text className={classes.text}>
                <b>
                  {pl
                    ? pl
                        .toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                        .replace(".", ".")
                    : ""}
                </b>
              </Text>
            </Group>
            <Group className={classes.item}>
              <Text className={classes.text}>Última Atualização</Text>
              <Text className={classes.text}>
                <b>
                  <Moment>{data.ult_att}</Moment>
                </b>
              </Text>
            </Group>
          </Flex>
        </Group>
      </Card>
    </div>
  );
}
