import {
  createStyles,
  Group,
  Text,
  UnstyledButton,
  UnstyledButtonProps,
} from "@mantine/core";
import { IconUser } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  user: {
    display: "block",
    background: theme.colors.gray[1],
    borderRadius: 10,
    width: "100%",
    padding: theme.spacing.xs,
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.lg,
    color: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.black,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.gray[0],
    },
  },
}));

interface UserButtonProps extends UnstyledButtonProps {
  name: string;
  email: string;
  adm?: boolean;
}

export function UserInfo({ name, email, adm }: UserButtonProps) {
  const { classes } = useStyles();

  return (
    <UnstyledButton className={classes.user}>
      <Group>
        <IconUser />

        <div style={{ flex: 1 }}>
          <Text size="sm" weight={500}>
            {adm ? <b>Conta Administrador</b> : ""}
          </Text>
          <Text size="sm" weight={500}>
            {name}
          </Text>

          <Text color="dimmed" size="xs">
            {email}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  );
}
