import { createStyles, Title } from "@mantine/core";
export function TitlePage({ title }: any) {
  const useStyles = createStyles((theme) => ({
    titlePage: {
      fontSize: 26,
      fontWeight: 500,
      color: theme.colors.blue[6],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 20,
      },

      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: theme.colors.blue[6],
        width: 100,
        height: 2,
        marginBottom: 15,
      },
    },
  }));
  const { classes, theme } = useStyles();
  return (
    <Title order={3} className={classes.titlePage} mt="sm">
      {title}
    </Title>
  );
}
