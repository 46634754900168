import { MediaQuery } from "@mantine/core";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useAuth } from "../../../context/authContext";
import "./print.scss";

export default function LineCharts({ cdiData, fundData, print }: any) {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const ReRenderChartCdixFundo = () => {
      const dataToChartLine = [];
      if (cdiData && fundData) {
        for (let i = 0; i < fundData?.length; i++) {
          dataToChartLine?.push({
            date: moment(fundData[i]?.data).format("DD/MM/YY"),
            dateCDI: moment(cdiData[i]?.data).format("DD/MM/YY"),
            cdi: (cdiData[i]?.cota - 1) * 100,
            fundo: (fundData[i]?.cota - 1) * 100,
          });
        }
      }

      setData(dataToChartLine);
      return dataToChartLine;
    };

    ReRenderChartCdixFundo();
  }, [cdiData, fundData]);

  const {
    user,
    user: { is_private },
  } = useAuth();

  const formatXAxis = (tickFormat: string) => {
    return moment(tickFormat, "DD-MM-YYYY").format("MM/YY");
  };

  const formatLabel = (label: any) => {
    return `${label?.toFixed(0)} %`;
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "white",
            width: 150,
            display: "flex",
            flexDirection: "column",
            margin: 0,
            borderRadius: 10,
            fontWeight: 300,
            padding: 10,
            border: "none",
            fontSize: 12,
            outline: "none",
            boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
          }}
          className="custom-tooltip"
        >
          {label}

          <b>{` CDI: ${payload[0].value.toFixed(8)}%`}</b>

          <b>{` Fundo: ${payload[1].value.toFixed(2)}%`}</b>

          <b>{` Fundo / CDI: ${(
            (payload[1].value.toFixed(2) / payload[0].value.toFixed(2)) *
            100
          ).toFixed(2)}%`}</b>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="print">
        <LineChart
          width={1200}
          height={350}
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: -20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="0.8 5" />
          <XAxis
            dataKey="date"
            tickFormatter={(e) => formatXAxis(e)}
            tick={{ fontSize: 11, fontWeight: 200 }}
          />
          <YAxis
            domain={[1]}
            tick={{ fontSize: 10, fontWeight: 200 }}
            tickFormatter={(tick) => `${formatLabel(tick)}`}
          />

          <Tooltip />
          <Legend />
          <Line
            strokeWidth={2.5}
            type="monotone"
            dataKey="cdi"
            dot={false}
            stroke="#fd7e14"
            activeDot={{ r: 5 }}
          />
          <Line
            strokeWidth={1.5}
            type="monotone"
            dataKey="fundo"
            dot={false}
            stroke="#928EDB"
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </div>
      <div className="notprint">
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <ResponsiveContainer width={"100%"} height={400}>
            <LineChart
              width={400}
              height={400}
              data={data}
              margin={{
                top: 5,
                right: 5,
                left: -20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="0.8 5" />
              <XAxis
                dataKey="date"
                tickFormatter={(e) => formatXAxis(e)}
                tick={{ fontSize: 11, fontWeight: 200 }}
              />
              <YAxis
                domain={[1]}
                tick={{ fontSize: 10, fontWeight: 200 }}
                tickFormatter={(tick) => `${formatLabel(tick)}`}
              />

              <Tooltip
                wrapperStyle={{ outline: "none" }}
                content={<CustomTooltip />}
              />
              <Legend />
              <Line
                strokeWidth={2.5}
                type="monotone"
                dataKey="cdi"
                dot={false}
                stroke="#fd7e14"
                activeDot={{ r: 5 }}
              />
              <Line
                strokeWidth={1.5}
                type="monotone"
                dataKey="fundo"
                name={is_private ? "fundo" : "carteira"}
                dot={false}
                stroke="#928EDB"
                activeDot={{ r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </MediaQuery>

        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <ResponsiveContainer width={"100%"} height={300}>
            <LineChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 5,
                right: 5,
                left: -30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="0.8 5" />
              <XAxis
                tickFormatter={(e) => formatXAxis(e)}
                dataKey="date"
                tick={{ fontSize: 11, fontWeight: 200 }}
              />
              <YAxis
                domain={[1]}
                tick={{ fontSize: 10, fontWeight: 200 }}
                tickFormatter={(tick) => `${formatLabel(tick)}`}
              />

              <Tooltip
                wrapperStyle={{ outline: "none" }}
                content={<CustomTooltip />}
              />
              <Legend />
              <Line
                strokeWidth={2.5}
                type="monotone"
                dataKey="cdi"
                dot={false}
                stroke="#fd7e14"
                activeDot={{ r: 5 }}
              />
              <Line
                strokeWidth={1.5}
                type="monotone"
                dataKey="fundo"
                dot={false}
                stroke="#928EDB"
                activeDot={{ r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </MediaQuery>
      </div>
    </>
  );
}
