import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import { useHotkeys, useLocalStorage } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { AppUiProvider } from "./context/AppUiContext";
import { AuthProvider } from "./context/authContext";
import { Rotas } from "./routes/index";

export default function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  useHotkeys([["mod+J", () => toggleColorScheme()]]);
  return (
    <div className="App">
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          theme={{
            loader: "dots",
            headings: {
              fontWeight: 800,
              fontFamily: "Inter",
              color: "#6b6a6c",
            },
            defaultGradient: {
              from: "orange",
              to: "red",
              deg: 45,
            },
            breakpoints: {
              xs: 500,
              sm: 800,
              md: 1000,
              lg: 1200,
              xl: 1400,
            },
            fontFamily: "Inter, sans-serif",
            colorScheme: "light",
            fontSizes: { md: 14 },
            radius: { sm: 7 },
            colors: {
              blue: [
                "#e9eeff",
                "#c6cbef",
                "#a1a8de",
                "#7c85d0",
                "#5762c1",
                "#3e48a8",
                "#2f3883",
                "#21285f",
                "#12183b",
                "#05071a",
              ],
            },
            primaryColor: "blue",
          }}
          withCSSVariables
          withGlobalStyles
          withNormalizeCSS
        >
          <AuthProvider>
            <AppUiProvider>
              <ModalsProvider>
                <Rotas />
              </ModalsProvider>
            </AppUiProvider>
          </AuthProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </div>
  );
}
