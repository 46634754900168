import { Styles } from "./styles";

interface BoxProps {
  children?: React.ReactNode;
  data?: DataItem;
  negative?: any;
}

interface DataItem {
  id: string;
  ativo: string;
  valor_findisp: number;
  date: string;
  cnpj: string;
}

export default function RendaVariavel({ data }: any) {
  return (
    <Styles>
      <table>
        <thead>
          <tr>
            <th>TIPO</th>
            <th>POSIÇÃO</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: any) => {
            return (
              <tr key={index}>
                <td>{item.produto}</td>
                <td>
                  {item.posicao
                    .toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                    .replace(".", ".")}
                </td>
              </tr>
            );
          })}

          <tr></tr>
        </tbody>
      </table>
    </Styles>
  );
}
