import {
  Anchor,
  Button,
  Center,
  createStyles,
  Paper,
  PasswordInput,
  Text,
  Title,
} from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { showNotification } from "@mantine/notifications";
import { IconCircleCheck, IconExclamationMark, IconLock } from "@tabler/icons";
import LogoIcon from "../../../assets/icon.svg";
import { PasswordValidation } from "../../../components/input/passwordValidation";
import ChangePass from "../../../interfaces/ChangePass";
import forgot from "../../../services/forgot";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundImage: "url(https://i.ibb.co/dgRCXSY/MOSESVIX-01135-1.jpg)",
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: "100vh",
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Poppins, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export function NewPassword() {
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      forgot.checkToken(id).then((res) => {
        if (res.status === 200 || res.status === 201) {
          return;
        }

        if (
          res.status === 401 ||
          res.status === 403 ||
          res.status === 409 ||
          res.status === 500
        ) {
          //navigate("/tokeninvalido");
        }
      });
    } else {
      //navigate("/tokeninvalido");
    }
  }, []);

  const ChangePass = useCallback(async (dataPost: ChangePass) => {
    forgot.newPassword(dataPost).then((res) => {
      if (res.status === 200 || res.status === 201) {
        showNotification({
          message: res.data.message,
          color: "green",
          icon: <IconCircleCheck />,
        });
      }

      if (
        res.status === 401 ||
        res.status === 403 ||
        res.status === 409 ||
        res.status === 400 ||
        res.status === 500
      ) {
        showNotification({
          title: "Erro!",
          message: res.data.message,
          color: "red",
          icon: <IconExclamationMark />,
        });
      }
    });
  }, []);

  function handleChangePassword(value: string) {
    setPassword(value);
  }

  function handleChangeConfirmPassword(value: string) {
    setconfirmPassword(value);
  }

  const onSubmit = async () => {
    if (id) {
      ChangePass({ token: id, password, confirmpassword });
    }
  };

  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={25} shadow="xl">
        <Center mb={-10}>
          <img src={LogoIcon} width={35}></img>
        </Center>
        <Center>
          <Title
            color="blue"
            variant="gradient"
            gradient={{ from: "#7c85d0", to: "#21285f", deg: 95 }}
            inherit
            order={4}
            mt="xs"
          >
            Alphamar Private
          </Title>
        </Center>
        <Title
          order={5}
          weight={300}
          mt={-5}
          mb={5}
          color="#373A40"
          align="center"
        >
          Redefinir senha <br />
        </Title>

        <PasswordValidation
          OnChangePassword={handleChangePassword}
        ></PasswordValidation>

        <PasswordInput
          mb={10}
          icon={<IconLock size={14}></IconLock>}
          label="Confirme sua nova senha"
          placeholder="Confirmar nova senha"
          onChange={(e) => handleChangeConfirmPassword(e.target.value)}
          mt="md"
          required
          size="md"
        />
        <Button onClick={() => onSubmit()} fullWidth mt="xl" size="md">
          Redefinir Senha
        </Button>

        <Text align="left" mt="md">
          Voltar para tela de{" "}
          <Anchor component={Link} to="/login" weight={700}>
            Login
          </Anchor>
        </Text>
      </Paper>
    </div>
  );
}
