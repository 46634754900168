import { Center, createStyles, Grid, Title } from "@mantine/core";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { CardFundo } from "../../components/CardFundo";
import { Ifundos } from "../../interfaces/IFundos";
import loader from "../../lottie/loader.json";
import fundos from "../../services/fundos/fundos";

export function Fundos() {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);

  const useStyles = createStyles((theme) => ({
    title: {
      fontSize: 26,
      fontWeight: 500,
      color: theme.colors.blue[6],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 20,
      },

      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: theme.colors.blue[6],
        width: 100,
        height: 2,
        marginBottom: 15,
      },
    },
    titlePage: {
      fontSize: 26,
      fontWeight: 500,
      color: theme.colors.blue[6],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 20,
      },

      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: theme.colors.blue[6],
        width: 100,
        height: 2,
        marginBottom: 15,
      },
    },
  }));

  const { classes, theme } = useStyles();

  useEffect(() => {
    setLoading(true);
    fundos
      .all()
      .then((res: any) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Center>
        <Lottie height={50} width={50} animationData={loader} />
      </Center>
    );
  }

  return (
    <div>
      <Title order={3} className={classes.titlePage} mt="sm">
        Fundos Gerenciados
      </Title>

      <Grid>
        {data?.map((fundo: Ifundos) => {
          return (
            <Grid.Col md={12} lg={6}>
              <CardFundo data={fundo}></CardFundo>
            </Grid.Col>
          );
        })}
      </Grid>
    </div>
  );
}
