import { Accordion, Group, Text } from "@mantine/core";
import { IPostions } from "../../interfaces/IPositions";
import FundosCotas from "./contents/FundosCotas";
import RendaVariavel from "./contents/RendaVariavel";
// import "./print.scss";

interface AccordionLabelProps {
  label: string;
  description: string;
}

interface PositionProps {
  data?: IPostions;
}

interface IDataFormated {
  tag_section: any;
  total: any;
  data: any;
}

export function Positions({ data }: PositionProps) {

  const dataFormated = [
    {
      tag_section: "Cotas",
      total: data
        ?.filter((item) => item.tag_section === "cotas")
        .reduce((a, b) => a + b.posicao, 0),
      data: data?.filter((item) => item.tag_section === "cotas"),
    },
    {
      tag_section: "Caixa",
      total: data
        ?.filter((item) => item.tag_section === "caixa")
        .reduce((a, b) => a + b.posicao, 0),
      data: data?.filter((item) => item.tag_section === "caixa"),
    },
    {
      tag_section: "Título Público",
      total: data
        ?.filter((item) => item.tag_section === "titpublico")
        .reduce((a, b) => a + b.posicao, 0),
      data: data?.filter((item) => item.tag_section === "titpublico"),
    },
    {
      tag_section: "Título Privado",
      total: data
        ?.filter((item) => item.tag_section === "titprivado")
        .reduce((a, b) => a + b.posicao, 0),
      data: data?.filter((item) => item.tag_section === "titprivado"),
    },
    {
      tag_section: "Despesas",
      total: data
        ?.filter((item) => item.tag_section === "despesas")
        .reduce((a, b) => a + b.posicao, 0),
      data: data?.filter((item) => item.tag_section === "despesas"),
    },
    {
      tag_section: "Ações",
      total: data
        ?.filter((item) => item.tag_section === "acoes")
        .reduce((a, b) => a + b.posicao, 0),
      data: data?.filter((item) => item.tag_section === "acoes"),
    },
    {
      tag_section: "Provisão",
      total: data
        ?.filter((item) => item.tag_section === "provisao")
        .reduce((a, b) => a + b.posicao, 0),
      data: data?.filter((item) => item.tag_section === "provisao"),
    },
  ];

  const dataFormatedandRemoved = dataFormated.filter(
    (item: IDataFormated) => item.total > 0
  );

  return (
    <>
      {dataFormatedandRemoved?.map((item, index) => (
        <>
          <div className="page-break-dois"></div>
          <Accordion
            defaultValue={[`${index}`]}
            multiple={true}
            chevronPosition="right"
            variant="contained"
          >
            <Accordion.Item value={`${index}`} key={index}>
              <Accordion.Control>
                <Group noWrap>
                  <Text weight={600} size="lg">
                    {item.total
                      ? item.total
                          .toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })
                          .replace(".", ".")
                      : 0}
                  </Text>
                  <div>
                    <Text weight={500}>{item.tag_section}</Text>
                  </div>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                {item.tag_section === "Cotas" ? (
                  <FundosCotas data={item.data}></FundosCotas>
                ) : (
                  <RendaVariavel data={item.data}></RendaVariavel>
                )}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </>
      ))}
    </>
  );
}
