import { createContext, ReactNode, useContext, useState } from "react";

type AppUiProviderProps = {
  children: ReactNode;
};

interface AppUiContextData {
  menuOpen: any;
  handleChangeMenuOpen(): void;
}

export const AppUiContext = createContext<AppUiContextData>(
  {} as AppUiContextData
);

export const AppUiProvider = ({ children }: AppUiProviderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  function handleChangeMenuOpen() {
    setMenuOpen((current) => !current);
  }

  return (
    <AppUiContext.Provider value={{ menuOpen, handleChangeMenuOpen }}>
      {children}
    </AppUiContext.Provider>
  );
};

export function useAppUi(): AppUiContextData {
  const context = useContext(AppUiContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
