import {
  AppShell,
  Burger,
  createStyles,
  Header,
  Image,
  MediaQuery,
  Navbar,
  Title,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useState } from "react";
import logo from "../../assets/alphamar_private1.png";
import { NavBarAlpha } from "../../components/navbar/navbar";
import { useAppUi } from "../../context/AppUiContext";
import { useAuth } from "../../context/authContext";
import { NavBarAlphaAdmin } from "../navbar/navbarAdm";
import { ToggleColor } from "../toggle/colorscheme";

export default function AppBase({ children }: any) {
  const theme = useMantineTheme();
  const { user } = useAuth();
  const [opened, setOpened] = useState(false);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const { menuOpen, handleChangeMenuOpen } = useAppUi();
  const useStyles = createStyles((theme) => ({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: theme.colors.blue[6],
    },
  }));
  const { classes } = useStyles();
  {
    console.log(user.role);
  }
  return (
    <AppShell
      styles={{
        main: {
          color:
            theme.colorScheme === colorScheme
              ? theme.colors.blue[1]
              : theme.colors.dark[4],
          background:
            theme.colorScheme === colorScheme
              ? theme.colors.gray[1]
              : theme.colors.dark[4],
        },
      }}
      navbarOffsetBreakpoint={1400}
      asideOffsetBreakpoint="lg"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint={1400}
          hidden={!menuOpen}
          width={{ sm: 300, lg: 300 }}
        >
          {user.role === "user" ? (
            <>
              <NavBarAlpha />
            </>
          ) : (
            <>
              <NavBarAlphaAdmin />
            </>
          )}
        </Navbar>
      }
      header={
        <Header height={70} p="lg" className={classes.container}>
          <MediaQuery largerThan={1400} styles={{ display: "none" }}>
            <Burger
              opened={menuOpen}
              onClick={() => handleChangeMenuOpen()}
              size="md"
              color={theme.colors.gray[1]}
            />
          </MediaQuery>

          <Title
            color="blue"
            variant="gradient"
            gradient={{ from: "#7c85d0", to: "#21285f", deg: 95 }}
            inherit
            order={4}
          >
            <Image
              radius="md"
              src={logo}
              alt="logo alphamar private"
              width={155}
            />
          </Title>
          <ToggleColor></ToggleColor>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
}
