import {
  Badge,
  Card,
  createStyles,
  Divider,
  Flex,
  Group,
  Text,
  Title,
} from "@mantine/core";
import moment from "moment";
import "moment-timezone";
import "moment/locale/pt-br";

import Moment from "react-moment";

moment.tz.setDefault("UTC");
moment.locale("pt-BR");

interface IpropsHeader {
  cnpj: string;
  createdAt: string;
  date_invest_init: string;
  id: string;
  name_fund: string;
  status: string;
  tx_adm: number;
  tx_performace: number;
  updatedAt: string;
  lastUpdateOfFund: string;
}

interface Props {
  data: IpropsHeader;
  pl?: number;
}

export function HeaderFundo({ data, pl }: Props) {
  const useStyles = createStyles((theme) => ({
    title: {
      fontWeight: 900,
      color: theme.colors.dark[3],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 20,
      },
    },
    item: {
      gap: 1,
      display: "flex",
      flexDirection: "column",
      margin: 0,
      textAlign: "center",
    },
    text: {
      fontWeight: 400,
      margin: 0,
      color: theme.colors.dark[2],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 10,
      },
    },

    description: {
      maxWidth: 600,
      margin: "auto",

      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: theme.fn.primaryColor(),
        width: 85,
        height: 2,
        marginTop: theme.spacing.sm,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },

    card: {
      border: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1]
      }`,
    },
  }));
  
  const { classes, theme } = useStyles();
  return (
    <div>
      <Card shadow="xs">
        <Group>
          <Title order={3} className={classes.title}>
            {data.name_fund}
          </Title>
          <Badge color="green" variant="dot">
            Ativo
          </Badge>
        </Group>
        <Group mt="xs">
          <Text className={classes.text}>
            <b>CNPJ</b> {data.cnpj}
          </Text>
          <Text className={classes.text}>
            <b>INÍCIO</b>{" "}
            <Moment>
              {data.date_invest_init
                ? data.date_invest_init
                : data.date_invest_init}
            </Moment>
          </Text>
        </Group>
        <Divider my="xs" color={theme.colors.gray[2]} />

        <Group mt="xs" position="left" noWrap spacing="xl">
          <Flex>
            {data.cnpj === "35020145000139" ? (
              <>
                <Group className={classes.item}>
                  <Text className={classes.text}>Tx. de Administração</Text>
                  <Text className={classes.text}>
                    <b>{data.tx_adm}%</b>
                  </Text>
                </Group>
                <Group className={classes.item}>
                  <Text className={classes.text}>Tx de Performace</Text>
                  <Text className={classes.text}>
                    <b>{data.tx_performace}%</b>
                  </Text>
                </Group>
              </>
            ) : (
              ""
            )}

            <Group className={classes.item}>
              <Text className={classes.text}>PL Atual:</Text>
              <Text className={classes.text}>
                <b>
                  {pl
                    ? pl
                        .toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                        .replace(".", ".")
                    : ""}
                </b>
              </Text>
            </Group>
            <Group className={classes.item}>
              <Text className={classes.text}>Última Atualização</Text>
              <Text className={classes.text}>
                <b>
                  <Moment>{data.lastUpdateOfFund}</Moment>
                </b>
              </Text>
            </Group>
          </Flex>
        </Group>
      </Card>
    </div>
  );
}
