import { Grid } from "@mantine/core";
import { CartaGestaoCard } from "../../components/CartaoGestaoCard";
import { TitlePage } from "../../components/TitlePage";

export function CartasGestao() {
  return (
    <>
      <TitlePage title="Cartas de Gestão" />
      <Grid>
        <Grid.Col md={6}>
          <CartaGestaoCard
            image={
              "https://static.wixstatic.com/media/e71fef_33825ec563504256bd10507c5c76993f~mv2.png/v1/fill/w_740,h_525,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/e71fef_33825ec563504256bd10507c5c76993f~mv2.png"
            }
            link={
              "https://www.alphamarinvest.com/post/carta-de-gest%C3%A3o-de-julho-2022"
            }
            title={"Carta de Gestão de Julho/2022."}
            description={
              "Confira na integra a visão da Alphamar Investimentos acerca do cenário econômico de Junho/2022."
            }
            rating={""}
            author={{
              name: "Private Alphamar",
              image: "",
            }}
          ></CartaGestaoCard>
        </Grid.Col>
        <Grid.Col md={6}>
          <CartaGestaoCard
            image={
              "https://static.wixstatic.com/media/e71fef_33825ec563504256bd10507c5c76993f~mv2.png/v1/fill/w_740,h_525,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/e71fef_33825ec563504256bd10507c5c76993f~mv2.png"
            }
            link={
              "https://www.alphamarinvest.com/post/carta-de-gest%C3%A3o-de-julho-2022"
            }
            title={"Carta de Gestão de Julho/2022."}
            description={
              "Confira na integra a visão da Alphamar Investimentos acerca do cenário econômico de Junho/2022."
            }
            rating={""}
            author={{
              name: "Private Alphamar",
              image: "",
            }}
          ></CartaGestaoCard>
        </Grid.Col>
      </Grid>
    </>
  );
}
