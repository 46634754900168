import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { formatValueResumed } from "../../../helpers/formatNumberResumed";
import { IPostions } from "../../../interfaces/IPositions";

const colors = [
  "#70B0FA",
  "#3D6BD4",
  "#3D36B2",
  "#7DEBD9",
  "#00A7BD",
  "#017991",
  "#C996BF",
  "#850A4D",
  "#600336",
];

interface PositionProps {
  data?: IPostions;
}

export default function BarChartComponent({ data }: PositionProps) {
  const dataRemoveItems = data?.filter(
    (e) =>
      e.tag_section !== "provisao" &&
      e.tag_section !== "despesas" &&
      e.tag_section !== "caixa"
  );

  const dataFormated = [
    {
      name: "D0-7",
      value: dataRemoveItems
        ?.filter((item) => item.liq <= 7)
        .reduce((a, b) => a + b.posicao, 0),
    },
    {
      name: "D8-40",
      value: dataRemoveItems
        ?.filter((item) => item.liq > 7 && item.liq <= 40)
        .reduce((a, b) => a + b.posicao, 0),
    },
    {
      name: "D41-185",
      value: dataRemoveItems
        ?.filter((item) => item.liq > 40 && item.liq <= 185)
        .reduce((a, b) => a + b.posicao, 0),
    },
    {
      name: "D185+",
      value: dataRemoveItems
        ?.filter((item) => item.liq > 185)
        .reduce((a, b) => a + b.posicao, 0),
    },
  ];

  return (
    <>
      <div className="print">
        <BarChart width={545} height={350} data={data} barCategoryGap="1.5%">
          <XAxis dataKey="name" style={{ fontSize: 10, fontWeight: 400 }} />
          <Tooltip
            formatter={(value) =>
              value
                .toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
                .replace(".", ".")
            }
          />

          <Bar
            data={dataFormated}
            label={{
              position: "centerTop",
              angle: 0,
              fill: "white",
              fontWeight: 500,
              fontSize: 12,
              formatter: (value: number) => formatValueResumed(value),
            }}
            dataKey="value"
          >
            {dataFormated.map((entry, index) => (
              <Cell key={index} fill={colors[index]} />
            ))}
          </Bar>
        </BarChart>
      </div>
      <div className="notprint">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart width={545} height={300} data={data} barCategoryGap="1.5%">
            <XAxis dataKey="name" style={{ fontSize: 10, fontWeight: 400 }} />
            <Tooltip
              formatter={(value) =>
                value
                  .toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                  .replace(".", ".")
              }
            />

            <Bar
              data={dataFormated}
              label={{
                position: "centerTop",
                angle: 0,
                fill: "white",
                fontWeight: 500,
                fontSize: 12,
                formatter: (value: number) => formatValueResumed(value),
              }}
              dataKey="value"
            >
              {dataFormated.map((entry, index) => (
                <Cell key={index} fill={colors[index]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}
