import axios from "axios";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";

const token = localStorage.getItem("@Alphamar:token") || "aa";

//baseURL: "https://api.alphamarinvest.com",
//baseURL: "http://localhost:3000",

//const baseURL = "https://nest-jfmruzcboa-uc.a.run.app";
const baseURL = "https://nest-jfmruzcboa-uc.a.run.app";

const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use((config) => {
  let tokensData = localStorage.getItem("@Alphamar:token");
  config.headers.common["Authorization"] = `Bearer ${tokensData}`;
  return config;
});

api.interceptors.request.use(async (req) => {
  const token = localStorage.getItem("@Alphamar:token");

  if (req.url === "/login" || req.url === "/user/register" || token === null) {
    return req;
  }
  const refresh_token = localStorage.getItem("@Alphamar:refresh_token");
  const user = jwt_decode(token);
  const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
  if (!isExpired) {
    return req;
  }

  if (isExpired) {
    const response = await axios
      .post(
        `${baseURL}/refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${refresh_token}`,
          },
        }
      )
      .catch((res) => {
        if (res.response.data.statusCode === 403) {
          localStorage.clear();
          api.defaults.headers.common["Authorization"] = ``;
          return window.location.replace("/login");
        }
      });
    localStorage.setItem("@Alphamar:token", response.data.token);
    localStorage.setItem(
      "@Alphamar:refresh_token",
      response.data.refresh_token
    );
    req.headers.Authorization = `Bearer ${response.data.token}`;
    return req;
  }
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.data.error === "Unauthorized") {
      localStorage.clear();
      api.defaults.headers.common["Authorization"] = ``;
      //window.location.replace("/login");
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

export default api;
