import SignUpCredentials from "../interfaces/SignUpCredentials";
import api from "./adm/api";

export default {
  register: async (data: SignUpCredentials) => {
    const response = await api
      .post(`/user/register`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response.response);

    return response;
  },
  confirm: async (token: string) => {
    const response = await api
      .get(`/user/confirm/${token}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response.response);

    return response;
  },
};
