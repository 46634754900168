import { Card, createStyles, Group, Text, ThemeIcon } from "@mantine/core";
import { IconArrowDownRight, IconArrowUpRight } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  root: {
    height: "100%",
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
  card: {
    height: "100%",
  },
}));

export function ProfitCards({ cdi, fund }: any) {
  const { classes } = useStyles();
  const stats = cdi.map((stat: any) => {
    const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;

    return (
      <Card key={"teste"} shadow="xs" radius="md" className={classes.card}>
        <Group>
          <div>
            <Text
              color="dimmed"
              transform="uppercase"
              weight={700}
              size="xs"
              className={classes.label}
            >
              {stat.title}
            </Text>
            <Text weight={700} size="xl">
              {stat.value}
            </Text>
          </div>
          <ThemeIcon
            color="gray"
            variant="light"
            sx={(theme) => ({
              color: stat.diff > 0 ? theme.colors.teal[6] : theme.colors.red[6],
            })}
            size={38}
            radius="md"
          >
            <DiffIcon size={28} stroke={1.5} />
          </ThemeIcon>
        </Group>
        <Text color="dimmed" size="sm" mt="md">
          <Text
            component="span"
            color={stat.diff > 0 ? "teal" : "red"}
            weight={700}
          >
            {stat.diff}%
          </Text>{" "}
          comparado com a data inicial
        </Text>
      </Card>
    );
  });

  return <div className={classes.root}>{stats}</div>;
}
